import { GameConstants } from "./GameConstants";
import { GameVars } from "./GameVars";
import { Ball } from "./scenes/physics/Ball";

export class AchievementsManager {

    public static init(): void {
        //
    }   

    public static onGameOver(): void {

        const m = Math.floor(Ball.currentInstance.maxY);

        if (GameConstants.SPONSOR === GameConstants.SPONSOR_MINIJUEGOS) {

            
            miniplaySend2API("meters", m);
            miniplaySend2API("stars", GameVars.stars);

            miniplaySend2API("meters2", GameVars.continued ? m - GameVars.metersBeforeContinue : m);
            miniplaySend2API("stars2", GameVars.continued ? GameVars.stars - GameVars.starsBeforeContinue : GameVars.stars);

            miniplaySend2API("kicks", GameVars.kicks);
            miniplaySend2API("pigs", GameVars.pigHits);
        }

        if (GameConstants.SPONSOR === GameConstants.SPONSOR_LAGGED) {

            const api_awards = [];

            // 1
            if (m >= 100) {
                api_awards.push("flappyfoot_endlesgm_kwo001");
            }

            // 2
            if (GameVars.gameData.pigHits >= 250) {
                api_awards.push("flappyfoot_endlesgm_kwo002");
            }

            // 3
            if (GameVars.gameData.totalStars >= 5000) {
                api_awards.push("flappyfoot_endlesgm_kwo003");
            }

            // 4
            if (GameVars.gameData.totalMeters >= 1e4) {
                api_awards.push("flappyfoot_endlesgm_kwo004");
            }

            // 5
            if (GameVars.gameData.pigHits >= 1000) {
                api_awards.push("flappyfoot_endlesgm_kwo005");
            }

            // 6
            if (m >= 500) {
                api_awards.push("flappyfoot_endlesgm_kwo006");
            }

            // 7
            if (GameVars.gameData.kicks >= 500) {
                api_awards.push("flappyfoot_endlesgm_kwo007");
            }

            // 8
            if (GameVars.gameData.totalStars >= 15000) {
                api_awards.push("flappyfoot_endlesgm_kwo008");
            }

            LaggedAPI.Achievements.save(api_awards, function(response: any) {
                
                if (response.success) {
                    console.log("achievement saved");
                } else {
                    console.log(response.errormsg);
                }
            });
        }
    }
}
