import { GameManager } from "../GameManager";
import { GameVars } from "../GameVars";

export class BootScene extends Phaser.Scene {

    public static currentInstance: BootScene;
    
    constructor() {

        super("BootScene");
    }

    public preload(): void {

        this.load.image("load_icon", "assets/load_icon.png");
        this.load.image("endless-bounce-icon", "assets/endless_bounce_icon.png");
    }

    public create(): void {

        BootScene.currentInstance = this;

        GameManager.setCurrentScene(this);

        if (GameVars.currentScene.sys.game.device.os.desktop) {
            GameManager.init();
        } else {

            if (window.innerWidth > window.innerHeight) {
                document.getElementById("orientation").style.display = "block";
                document.getElementById("content").style.display = "none";
            } else {
                GameManager.init();
            }
        }
    }
}
