import { Ball } from "./Ball";
import { Forward } from "./Forward";
import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { StageContainer } from "../field/StageContainer";
import { GameManager } from "../../GameManager";
import { AudioManager } from "../../AudioManager";
import { Wave } from "../field/Wave";

export class Bumper extends Phaser.GameObjects.Container implements WaveItem {

    public static BALL_RELEASING_SPEED = 14;
    public static DELTA_ANGLE_BALL = .015;
    public static id = 0;

    private static DX = 150;

    public deactivated: boolean;
    public bumper: Phaser.GameObjects.Image;
    public hasBallAttached: boolean;
    public ball: Ball;
    public canBallBeAttached: boolean;
    public angleBall: number;
    public sensor: any;
    public wave: Wave;
    
    private f: number;
    private speed: number;
    private moves: boolean;
    private x1: number;
    private x2: number;
    private deltaAngleBall: number;
    private releaseAngle: number;
    private idleTextueName: string;
    private blinkTextueName: string;
    private withBallTextureName: string;

    constructor(scene: Phaser.Scene, wave: Wave, x: number, y: number, moves?: boolean) {
        
        super(scene);

        Forward.id ++;
    
        this.wave = wave;
        this.x = x;
        this.y = y; 
        this.name = GameConstants.BUMPER;
        this.moves = moves || false;
        this.hasBallAttached = false;
        this.canBallBeAttached = true;
        this.moves = moves || false;
        this.speed = .75;
        if (Bumper.id % 2 === 0) {
            this.speed *= -1;
        }
        
        this.f = 0;

        let currentTeamID = 5;

        this.idleTextueName = "bumper_" + currentTeamID + "_01";
        this.blinkTextueName = "bumper_" + currentTeamID + "_02";
        this.withBallTextureName = "bumper_" + currentTeamID + "_03";

        this.bumper = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", this.idleTextueName);
        this.add(this.bumper);

        if (this.x < GameConstants.GAME_WIDTH / 2) {
            this.bumper.scaleX = -1;
        }

        if (this.moves) {

            if (this.x < GameConstants.GAME_WIDTH / 3) {
                this.x1 = this.x;
                this.x2 = this.x1 + Bumper.DX;
                this.speed = 1;
            } else if (this.x > GameConstants.GAME_WIDTH * 2 / 3) {
                this.x1 = this.x - Bumper.DX;
                this.x2 = this.x;
                this.speed = -1;
            } else {
                this.x1 = this.x - Bumper.DX / 2;
                this.x2 = this.x + Bumper.DX / 2; 
                this.speed = 1;
            }
        } else {
            this.x1 = null;
            this.x2 = null;
            this.speed = null;
        }

        // mas facíl en el primer torneo
        this.sensor = this.scene.matter.add.circle(x, y, 34, {isSensor: true, isStatic: false}, 5);
        this.sensor.parent.gameObject = this;
        this.sensor.ignoreGravity = true;
        this.sensor.name = GameConstants.BUMPER;
        this.sensor.collisionFilter.category = GameConstants.PEGS_COLLISION_CATEGORY;
        this.sensor.collisionFilter.mask = GameConstants.BALLS_COLLISION_CATEGORY;

        // HAY Q HACER ESTO PQ EL METODO UPDATE NO SE UTILIZA DE MANERA AUTOMATICA
        this.scene.sys.updateList.add(this);
    }

    public preUpdate(time: number, delta: number): void {

        if (GameVars.gameOver) {
            return;
        }

        if (!GameVars.placingExtraForwards) {
            
            const cameraLimits = GameManager.getCameraLimits();

            if (this.y > cameraLimits.bottomY) {
                this.scene.matter.world.remove(this.sensor, true);
                StageContainer.currentInstance.removeItem(this);
                return;
            }
        }
        
        if (!this.canBallBeAttached) {

            this.f ++;

            if (this.f === 6) {
                this.canBallBeAttached = true;
            }
        }
        
        if (this.hasBallAttached && this.ball.body) {
            
            // sacudimos al bumper
            const dx = .025 - .05 * Math.random();
            const dy = .025 - .05 * Math.random();

            this.bumper.setOrigin(.5 + dx, .5 + dy);

            // hacemos girar la bola
            this.ball.x = 45 * Math.cos(this.angleBall) + this.x;
            this.ball.y = 45 * Math.sin(this.angleBall) + this.y;

            this.angleBall += this.deltaAngleBall;

            const angleBall = this.getCorrectedAngle(this.angleBall * 180 / Math.PI);

            if (angleBall > this.releaseAngle - 15 && angleBall < this.releaseAngle && this.deltaAngleBall > 0 || angleBall < this.releaseAngle + 15 && angleBall > this.releaseAngle && this.deltaAngleBall < 0) {
                this.deltaAngleBall *= .85;
            }

            this.f++;

            if (this.f === 120) {
                this.releaseBall();
            }
            
        } else {

            // el parpadeo
            if (Math.random() > .992 && this.bumper.frame.name === this.idleTextueName) {
                this.bumper.setFrame(this.blinkTextueName);
                this.f = 0;
            }

            if (this.bumper.frame.name === this.blinkTextueName) {
                if (this.f ++ === 10) {
                    this.bumper.setFrame(this.idleTextueName);
                }
            }
        }

        if (this.moves) {

            this.x += this.speed;
            this.sensor.position.x = this.x;

            if (this.x > this.x2 || this.x < this.x1) {
                this.speed *= -1;
            }
        }
    }

    public deactivate(): void {

        if (!this.scene) {
            return;
        }
        
        this.deactivated = true;

        this.alpha = GameConstants.ALPHA_DEACTIVATED_ITEMS;

        this.sensor.collisionFilter.mask = 0;
    }

    public attach(ball: Ball): void {

        this.f = 0;

        this.ball = ball;
        this.ball.attachToBumper(this);

        this.hasBallAttached = true;
        this.canBallBeAttached = false;

        const dx = ball.x - this.x;
        const dy = ball.y - this.y;

        this.bumper.setFrame(this.withBallTextureName);

        this.angleBall = Math.atan2(dy, dx);

        if (this.x < GameConstants.GAME_WIDTH / 2) {
            this.releaseAngle = 300;
        } else if (this.x > GameConstants.GAME_WIDTH / 2) {
            this.releaseAngle = 240;
        } else {
            this.releaseAngle = 270; // justo en medio
        }

        this.deltaAngleBall = this.getDeltaAngleBall();

        AudioManager.playSound("bumper");
    }

    public onBallStolen(): void {

        this.ball = null;

        this.hasBallAttached = false;
        this.canBallBeAttached = true;

        this.bumper.setFrame(this.idleTextueName);
        this.bumper.setOrigin(.5);
    }

    private releaseBall(): void {

        if (!this.ball) {
            return;
        }

        this.f = 0;
        
        this.hasBallAttached = false;
        
        this.bumper.setFrame(this.idleTextueName);
        this.bumper.setOrigin(.5);

        this.ball.releasedByBumper();
        this.ball = null;
    }

    private getDeltaAngleBall(): number {

        let deltaAngleBall: number;

        const angleBall = this.getCorrectedAngle(this.angleBall * 180 / Math.PI);

        const deltaAngle = this.releaseAngle - angleBall;

        // si esta pegado a un borde
        if (this.x <= GameConstants.GAME_WIDTH / 15 * 2.5 && this.ball.y > 0) { // ver esta muy pegado al borde izquierdo
            deltaAngleBall = -Bumper.DELTA_ANGLE_BALL;
        } else if (this.x >= GameConstants.GAME_WIDTH / 15 * (13 + .5) && this.ball.y > 0) { // ver esta muy pegado al borde derecho
            
            deltaAngleBall = Bumper.DELTA_ANGLE_BALL;

        } else {

            if (deltaAngle > 180) {
                deltaAngleBall = -Bumper.DELTA_ANGLE_BALL;
            } else if (deltaAngle < 0) {
                deltaAngleBall = -Bumper.DELTA_ANGLE_BALL;
            } else {
                deltaAngleBall = +Bumper.DELTA_ANGLE_BALL;
            }
        }

        if (Math.abs(deltaAngle) > 125 && Math.abs(deltaAngle) < 180) {
            deltaAngleBall *= 2;
        }
        
        return deltaAngleBall;
    } 

    private getCorrectedAngle(angle: number): number {

        if (angle >= 0 && angle <= 180) {
            return angle % 360;
        } else {
            return (360 + angle) % 360;
        }
    }
}
