import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { StageContainer } from "../field/StageContainer";
import { Ball } from "./Ball";
import { GameManager } from "../../GameManager";
import { AudioManager } from "../../AudioManager";
import { Wave } from "../field/Wave";

export class MovingPlatform extends Phaser.Physics.Matter.Sprite implements WaveItem {

    public static WIDHT = 70;
    public static HEIGHT = 28;

    private static DX = 150;
    private static VX = .75;

    public deactivated: boolean;
    public wave: Wave;
    
    private x1: number;
    private x2: number;
    private _y: number;
    private _x: number;
    private speed: number;
    private f: number;
    private anchorPointMoved: boolean;

    constructor(scene: Phaser.Scene, wave: Wave, x: number, y: number, right: boolean) {

        super(scene.matter.world, x, y, "texture_atlas_1", "moving-platform-left");

        this.wave = wave;
        this.deactivated = false;
        this._x = x;
        this._y = y;
        this.name = GameConstants.MOVING_PLATFORM;
        this.f = 0;
        this.anchorPointMoved = false;

        this.setBody({
            type: "rectangle",
            width: 70,
            height: 28
        }, null);

        this.setIgnoreGravity(true);
        this.setFixedRotation();
        this.setBounce(0);
        this.setFriction(.25);
        this.setCollisionCategory(GameConstants.PEGS_COLLISION_CATEGORY);
        this.setCollidesWith(GameConstants.BALLS_COLLISION_CATEGORY);

        this.x1 = this.x - MovingPlatform.DX / 2;
        this.x2 = this.x + MovingPlatform.DX / 2; 
        this.speed = right ? MovingPlatform.VX : -MovingPlatform.VX;
        
        // HAY Q HACER ESTO PQ EL METODO UPDATE NO SE UTILIZA DE MANERA AUTOMATICA
        this.scene.sys.updateList.add(this);
    }

    public preUpdate(time: number, delta: number): void {

        super.preUpdate(time, delta);

        if (!GameVars.placingExtraForwards) {
            
            const cameraLimits = GameManager.getCameraLimits();

            if (this.y > cameraLimits.bottomY) {

                StageContainer.currentInstance.removeItem(this);
                return;
            }
        }

        if ((this.x >= this.x2 && this.speed > 0) || (this.x <= this.x1 && this.speed  < 0)) {
            this.speed *= -1;
        } 
        
        this._x += this.speed;

        this.setPosition(this._x, this._y);
        this.setVelocityX(this.speed);
        this.setVelocityY(0);

        if (this.anchorPointMoved) {

            this.f ++;

            if (this.f === 2) {
                this.anchorPointMoved = false;
                this.setOrigin(.5);
            }
        }
    }

    public deactivate(): void {

        if (!this.scene) {
            return;
        }
        
        this.deactivated = true;

        this.alpha = GameConstants.ALPHA_DEACTIVATED_ITEMS;

        this.setCollidesWith([]);
    }

    public hit(ball: Ball): void {
        
        this.f = 0;
        this.anchorPointMoved = true;

        if (ball.x < this.x + MovingPlatform.WIDHT * .525 && ball.x > this.x - MovingPlatform.WIDHT * .525 ) {

            if (ball.y > this.y) {
                this.setOrigin(.5, .4);
            } else if (ball.y < this.y) {
                this.setOrigin(.5, .6);
            }

        } else {

            if (ball.x < this.x) {
                this.setOrigin(.45, .5);
            } else {
                this.setOrigin(.55, .5);
            }
        }

        AudioManager.playSound("rebote_pin");
    }
}
