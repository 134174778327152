import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";

export class HUD extends Phaser.GameObjects.Container {
    
    private levelLabel: Phaser.GameObjects.Text;
    private capsulesContainer: Phaser.GameObjects.Container;
    private starsLabel: Phaser.GameObjects.Text;
    private distanceLabel: Phaser.GameObjects.Text;
    private fps: Phaser.GameObjects.Text;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        this.setScrollFactor(0);

        let px = GameConstants.GAME_WIDTH * .5;

        this.capsulesContainer = new Phaser.GameObjects.Container(this.scene);
        this.capsulesContainer.y = GameConstants.GAME_HEIGHT;
        this.capsulesContainer.scaleY = GameVars.scaleY;
        this.add(this.capsulesContainer);

        const starsCapsule = new Phaser.GameObjects.Image(this.scene, px, 0, "texture_atlas_1", "capsule_stars_hud");
        starsCapsule.setOrigin(.5, 1);
        this.capsulesContainer.add(starsCapsule);

        this.starsLabel = new Phaser.GameObjects.Text(this.scene, starsCapsule.x + 18, -30, GameVars.formatNumber(GameVars.stars), {fontFamily: "OswaldDemibold", fontSize: "24px", color: "#fada22"});
        this.starsLabel.setOrigin(1, .5);
        this.capsulesContainer.add(this.starsLabel);

        px = GameConstants.GAME_WIDTH - (GameConstants.GAME_WIDTH - GameConstants.FIELD_WIDTH) * .5 - 75;

        this.scene.sys.updateList.add(this);

        if (GameConstants.DEVELOPMENT || GameVars.screenshotMode || GameVars.editingLevels) {

            this.fps = new Phaser.GameObjects.Text(this.scene, (GameConstants.GAME_WIDTH + GameConstants.FIELD_WIDTH) * .5 - 15, 120 * GameVars.scaleY, "", { fontFamily: "OswaldDemibold", fontSize: "20px", color: "#FFFF00"});
            this.fps.setOrigin(1, .5);
            this.fps.scaleY = GameVars.scaleY;
            this.add(this.fps);

        } else {
            this.fps = null;
        }
    }

    public preUpdate(time: number, delta: number): void {

        if (GameConstants.DEVELOPMENT || GameVars.editingLevels) {
            this.fps.text = Math.round(this.scene.game.loop.actualFps).toString();
        }
    }

    public updateStars(): void {

        if (this.starsLabel) {
            this.starsLabel.text = GameVars.formatNumber(GameVars.stars);
        }
    }

    public nextWave(): void {

        if (GameConstants.DEVELOPMENT || GameVars.editingLevels) {
            this.levelLabel.text = "level " + GameVars.gameData.currentWave.toString();
        }
    }

    public levelOver(): void {
        
        if (this.capsulesContainer) {
            this.scene.tweens.add({
                targets: this.capsulesContainer,
                y: GameConstants.GAME_HEIGHT + 75,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 350,
                onComplete: function(): void {
                    this.capsulesContainer.visible = false;
                },
                onCompleteScope: this
            });
        }
    }

    public onContinue(): void {

        this.capsulesContainer.visible = true;
        
        this.capsulesContainer.y = GameConstants.GAME_HEIGHT;
    }
}
