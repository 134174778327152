import { GameVars } from "../../GameVars";

import { GameConstants } from "../../GameConstants";
import { DistanceMarker } from "./DistanceMarker";
import { StageContainer } from "./StageContainer";

export class StarsEffect extends Phaser.GameObjects.Container {

    private stars: {star: Phaser.GameObjects.Image, f: number, smoothFactor: number, levitation_dx: number, levitation_dy: number}[];
    private f: number;

    private dx: number;
    private dy: number;

    constructor(scene: Phaser.Scene, value: number) {

        super(scene);  

        this.f = 0;

        this.stars = [];

        const px = DistanceMarker.BADGE_PX;
        const py = - (GameVars.dMarker - GameConstants.DELTA_METERS_MARKER) * GameConstants.PIXELS_TO_METERS_FACTOR - 25;

        for (let i = 0; i < value; i ++) {

            const star = new Phaser.GameObjects.Image(this.scene, px + 12 - 24 * Math.random(), py + 12 - 24  * Math.random(), "texture_atlas_1", "star_points_1");
            this.add(star);

            const angle = 2 * Math.PI * i / value;
            const levitation_dx = 3.5 * Math.cos(angle);
            const levitation_dy = 3.5 * Math.sin(angle);

            this.stars.push({star: star, f: 15 + Math.floor(25 * Math.random()), smoothFactor: .0125 * (1 +  Math.random()), levitation_dx: levitation_dx, levitation_dy: levitation_dy});
        }

        this.dx = 500;
        this.dy = -800;
       
        // HAY Q HACER ESTO PQ EL METODO UPDATE NO SE UTILIZA DE MANERA AUTOMATICA
        this.scene.sys.updateList.add(this);
    } 

    public preUpdate(): void {

        this.f ++;

        let sumDistances = 0;

        for (let i = 0; i < this.stars.length; i ++) {

            const star = this.stars[i].star;
            const framesLevitating = this.stars[i].f;

            if (this.f < framesLevitating) {

               star.x += this.stars[i].levitation_dx;
               star.y += this.stars[i].levitation_dy;
        
            } else {

                const smoothFactor = this.stars[i].smoothFactor;

                this.stars[i].smoothFactor *= 1.075;

                const dx = (GameConstants.FIELD_WIDTH * .5 + this.dx - star.x) * smoothFactor;
                const dy = (StageContainer.currentInstance.getCameraLimits().bottomY + this.dy - star.y) * smoothFactor;
               
                this.dx *= .98;
                this.dy *= .985;
                
                sumDistances += Math.abs(dx);
    
                star.x += dx;
                star.y += dy;
            }
        }   

        if (this.f > 50 && sumDistances < 5 ) {
             
           this.destroy();
        }
    }
}
