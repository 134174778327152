import { GameManager } from "../../GameManager";
import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { GameTitle } from "./GameTitle";
import { AudioButtonContainer } from "../AudioButtonContainer";
import { EndlessModePlayButtonContainer } from "../EndlessModePlayButtonContainer";
import { DevelopmentMenu } from "./DevelopmentMenu";
import { AudioManager } from "../../AudioManager";

export class SplashScene extends Phaser.Scene {

    public static currentInstance: SplashScene;

    private gameTitle: GameTitle;
    private keyJ: Phaser.Input.Keyboard.Key;
    
    constructor() {

        super("SplashScene");
    }

    public create(): void {
        
        SplashScene.currentInstance = this;

        GameManager.setCurrentScene(this);

        const backgroud = this.add.image(GameConstants.GAME_WIDTH / 2, GameConstants.GAME_HEIGHT / 2, "home-background");
        backgroud.scaleY = GameVars.scaleY;

        if (this.game.scale.displaySize.aspectRatio < .5) {
            backgroud.scaleY *= 1.065;
        }

        const audioButtonContainer = new AudioButtonContainer(this);
        audioButtonContainer.x = GameConstants.GAME_WIDTH - 30;
        audioButtonContainer.y = 34 * GameVars.scaleY;
        audioButtonContainer.scaleY = GameVars.scaleY;
        this.add.existing(audioButtonContainer);

        if (GameConstants.EDITING_LEVELS) {
            const developmentMenu = new DevelopmentMenu(this);
            this.add.existing(developmentMenu);
        }

        this.gameTitle = new GameTitle(this);
        this.add.existing(this.gameTitle);

        const ball = this.add.image(GameConstants.GAME_WIDTH / 2, GameConstants.GAME_HEIGHT / 2 + 55 * GameVars.scaleY, "texture_atlas_1", "ball_splash_endless");
        ball.scaleY = GameVars.scaleY;

        this.tweens.add({
            targets: ball,
            scaleX: 1.05,
            scaleY: 1.05 * GameVars.scaleY,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 250,
            yoyo: true,
            repeat: -1
        });

        if (GameConstants.SECRET_ACCESS_TO_LEVEL_EDITION) {
            this.keyJ = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.J);
        }

        if (!this.sys.game.device.os.desktop ) {
            this.input.on("pointerup", () => {

                if (!this.game.scale.isFullscreen) {
                    this.game.scale.startFullscreen();
                    GameManager.setScaleFactor();
                }
            }, this);
        }

        const endlessModePlayButtonContainer = new EndlessModePlayButtonContainer(this);
        this.add.existing(endlessModePlayButtonContainer);

        const copyrightLabel = this.add.text(5, GameConstants.GAME_HEIGHT - 45 * GameVars.scaleY, "developed by ravalmatic", {fontFamily: "Arial", fontSize: "17.5px", color: "#FFFFFF"});
        copyrightLabel.alpha = .35;

        const licensingLabel = this.add.text(5, GameConstants.GAME_HEIGHT - 25 * GameVars.scaleY, "licensed to " + GameConstants.SPONSOR, {fontFamily: "Arial", fontSize: "17.5px", color: "#FFFFFF"});
        licensingLabel.alpha = .35;

        AudioManager.playSound("loop_splash", true);
    }
    
    public update(): void {

        if (GameConstants.SECRET_ACCESS_TO_LEVEL_EDITION && this.keyJ.isDown) {
            GameManager.enterDevelopmentLevelSelectionScene();
        }
    }
}
