import { GameVars } from "./GameVars";
import { GameConstants } from "./GameConstants";
import { AudioManager } from "./AudioManager";
import { StageContainer } from "./scenes/field/StageContainer";
import { Game } from "./Game";
import { ExtraForwardSelectionButtonsContainer } from "./scenes/development-level-selection/ExtraForwardSelectionButtonsContainer";
import { AchievementsManager } from "./AchievementsManager";
import { Ball } from "./scenes/physics/Ball";

const { SimplioUserInfoSDK } = require('@SimplioOfficial/simplio-user-info-sdk');

export class GameManager {

    public static init(): void {

        // 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
        const urlParams = new URLSearchParams(window.location.search);

        GameVars.simplioApiUrl = urlParams.get("env") || "test";
        GameVars.simplioUserId = urlParams.get("userId") || "test";
        GameVars.simplioGameId = "7flvuW7LxeLLwHSyy-H3VOHHlCSXy4JQMbf4PkUNju_EAzFufaFKEQ==";

        GameVars.simplio = new SimplioUserInfoSDK(GameVars.simplioApiUrl, GameVars.simplioGameId);

        GameVars.intitialised = true;
        GameVars.canvasTextures = {};
        GameVars.editingLevels = false;
        GameVars.screenshotMode = false;
        GameVars.firstEndlessLevel = null;
        GameVars.devNumExtraForwards = 0;
        GameVars.previousDevNumExtraForwards = 0;

        GameManager.setScaleFactor();
    
        GameManager.readGameData(); 
    }   

    public static setScaleFactor(): void {

        if (GameVars.currentScene.sys.game.device.os.desktop) {

            GameVars.scaleY = 1;
            GameVars.stageDisplayMode = StageContainer.STAGE_WITH_VERTICAL_STRIPES; 

        } else {
            
            GameVars.currentScene.game.scale.displaySize = GameVars.currentScene.game.scale.parentSize;
            GameVars.currentScene.game.scale.refresh();

            GameVars.scaleY = (GameConstants.GAME_HEIGHT / GameConstants.GAME_WIDTH) * Game.currentInstance.scale.displaySize.aspectRatio;

            if (Game.currentInstance.scale.displaySize.aspectRatio > .65) {
                GameVars.stageDisplayMode = StageContainer.STAGE_WITH_VERTICAL_STRIPES; // CASO IPAD
            } else {
                GameVars.stageDisplayMode = StageContainer.STAGE_FULL_SCREEN; 
            } 
        }
    }

    public static readGameData(): void {
        
        GameManager.getGameStorageData(
            GameConstants.SAVED_GAME_DATA_KEY,
            function(gameData: string): void {

                if (gameData) {
                    GameVars.gameData = JSON.parse(gameData);
                } else {
                    GameVars.gameData = {
                        muted: false,
                        extraForwardTutorialShown: false,
                        currentWave: 0,
                        currentWaveAttempts: 0,
                        endlessModePlayed: false,
                        bestScoreEndlessMode: 0,
                        extraForwardPositions: [],
                        pigHits: 0,
                        kicks: 0,
                        totalStars: 0,
                        totalMeters: 0
                    };
                }

                GameManager.startGame();
            }
        );
    }

    public static onGameAssetsLoaded(): void {
 
        AudioManager.init();
        AchievementsManager.init();

        if (GameConstants.SHOW_SPLASH) {
            GameManager.enterHomeScene();
        } else {
            GameManager.enterEndlessMode();
        }
    }

    public static enterHomeScene(): void {

        GameVars.currentScene.scene.start("SplashScene");
    }

    public static enterTutorialScene(): void {

        GameVars.currentScene.scene.start("TutorialScene");
    }

    public static enterDevelopmentLevelSelectionScene(): void {

        GameVars.editingLevels = true;
        
        GameVars.gameData.extraForwardTutorialShown = true;

        GameVars.currentScene.scene.start("DevelopmentLevelSelectionScene");
    }

    public static onNumExtraForwardsSelected(n: number): void {

        GameVars.devNumExtraForwards = n;

        ExtraForwardSelectionButtonsContainer.currentInstance.onNumExtraForwardsSelected();
    }

    public static nextWave(): void {
        // de momento nada
    }

    public static enterFieldScene(level?: number, extraForwardPositons?: {x: number, y: number}[], isEndless?: boolean, difficulty?: string): void {

        GameVars.placingExtraForwards = false;

        if (level === 0 || level) {
            GameVars.gameData.currentWave = level;
        } 

        if (GameVars.editingLevels) {
            GameVars.difficulty = difficulty || GameVars.difficulty;
        } else {
            GameVars.difficulty = GameConstants.ENDLESS_EASY;
        }

        GameVars.currentScene.scene.start("FieldScene");
      
        GameManager.writeGameData();
    }

    public static enterEndlessMode(): void {
       
        if (GameVars.currentScene.scene.key === "SplashScene") {
            GameVars.showTournamentLayer = true;

        } else  {
            GameVars.showTournamentLayer = false;
        } 

        GameManager.enterFieldScene(null, GameVars.gameData.extraForwardPositions, true);
    }

    public static enterLevelSelectionScene(scene: Phaser.Scene): void {
        
        scene.scene.start("FieldScene");
    }

    public static gameOver(): void {

        AchievementsManager.onGameOver();

        if (GameVars.gameData.bestScoreEndlessMode < GameVars.stars) {

            if (GameVars.gameData.bestScoreEndlessMode > 0) {
                GameVars.newRecord = true;
            }
            
            GameVars.gameData.bestScoreEndlessMode = GameVars.stars;
        }

        const m = Math.floor(Ball.currentInstance.maxY);

        GameVars.gameData.totalMeters += GameVars.continued ? m - GameVars.metersBeforeContinue : m;

        GameManager.writeGameData();
            
        GameManager.log("GAME OVER: " + GameVars.gameOverCause);
    }

    public static resetStoryMode(): void {

        GameVars.gameData.currentWave = 0;

        GameManager.writeGameData();

        GameManager.enterHomeScene();
    }

    public static setCurrentScene(scene: Phaser.Scene): void {

        GameVars.currentScene = scene;
    }

    public static getCameraLimits(): {topY: number, bottomY: number} {

        return {topY: GameVars.currentScene.cameras.main.scrollY, bottomY: GameVars.currentScene.cameras.main.scrollY + GameConstants.GAME_HEIGHT + 40};
    }

    public static switchScreenShotMode(): void {

        GameVars.screenshotMode = !GameVars.screenshotMode;
    }

    public static writeGameData(): void {

        if (GameVars.editingLevels) {
            return;
        }
        
        GameManager.setGameStorageData(
            GameConstants.SAVED_GAME_DATA_KEY, 
            GameVars.gameData,
            function(): void {
                GameManager.log("game data successfully saved");
            }
        );
    }

    public static log(text: string, error?: Error): void {
        
        if (!GameConstants.VERBOSE) {
            return;
        }
        
        if (error) {
            console.error(text + ":",  error);
        } else {
            console.log(text);
        }
    }

    private static startGame(): void {
        
        GameVars.currentScene.scene.start("PreloadScene");
    }

    private static getGameStorageData(key: string, successCb: Function): void {
        
        successCb(localStorage.getItem(key));
    }

    private static setGameStorageData(key: string, value: any, successCb: Function): void {
        
        localStorage.setItem(key, JSON.stringify(value));
        
        successCb();
    }
}
