import { GameVars } from "../../../GameVars";

export class ScoreContainer extends Phaser.GameObjects.Container {

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        const shine = new Phaser.GameObjects.Image(this.scene, 0, 20, "texture_atlas_1", "shine");
        shine.setScrollFactor(0);
        this.add(shine);

        if (GameVars.newRecord) {

            this.scene.tweens.add({
                targets: shine,
                angle: 360,
                ease: Phaser.Math.Easing.Linear.Linear,
                duration: 5000,
                yoyo: false,
                repeat: -1
            });

            this.scene.tweens.add({
                targets: shine,
                scaleX: 1.25,
                scaleY: 1.25,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 2000,
                yoyo: true,
                repeat: -1
            });
        }

        const w = 280;
        const h = 140;

        const scoreContainer = new Phaser.GameObjects.Graphics(this.scene);
        scoreContainer.setScrollFactor(0);
        scoreContainer.x = - w / 2;
        scoreContainer.y = 14;
        scoreContainer.fillStyle(0x223845);
        scoreContainer.fillRoundedRect(0, 0, w, h, 20);
        this.add(scoreContainer);

        const scoreBadge = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "badge_score");
        scoreBadge.setScrollFactor(0);
        this.add(scoreBadge);

        const scoreLabel = new Phaser.GameObjects.Text(this.scene, 0, scoreContainer.y + 70, GameVars.formatNumber(GameVars.stars), {fontFamily: "OswaldDemibold", fontSize: "58px", color: "#c5ffff"});
        scoreLabel.setScrollFactor(0);
        scoreLabel.setOrigin(.5);
        this.add(scoreLabel);

        const starsIcon = new Phaser.GameObjects.Image(this.scene, scoreLabel.width / 2 + 40, scoreContainer.y + 70, "texture_atlas_1", "star_points_gameover");
        starsIcon.setScrollFactor(0);
        this.add(starsIcon);

        if (GameVars.newRecord) {

            const newRecord  = new Phaser.GameObjects.Image(this.scene, - w / 2 - 10, scoreContainer.y + h / 2 - 45, "texture_atlas_1", "badge_new_record");
            newRecord.setScrollFactor(0);
            this.add(newRecord);

            this.scene.tweens.add({
                targets: newRecord,
                scaleX: 1.075,
                scaleY: 1.075,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 225,
                yoyo: true,
                repeat: -1
            });

        } else if (GameVars.gameData.bestScoreEndlessMode !== GameVars.stars) {

            scoreLabel.y -= 10;
            starsIcon.y -= 10;

            const recordLabel = new Phaser.GameObjects.Text(this.scene, 0, scoreContainer.y + 110, "BEST: " + GameVars.gameData.bestScoreEndlessMode, {fontFamily: "OswaldDemibold", fontSize: "22px", color: "#4c94b2"});
            recordLabel.setScrollFactor(0);
            recordLabel.setOrigin(.5);
            this.add(recordLabel);
        }
    }
}
