import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { StageContainer } from "../field/StageContainer";
import { GameManager } from "../../GameManager";
import { Wave } from "../field/Wave";
import { Tilemaps } from "phaser";

export class ExtraBall extends Phaser.Physics.Matter.Sprite implements WaveItem{

    public deactivated: boolean;
    public disappearing: boolean;
    public wave: Wave;

    constructor(scene: Phaser.Scene, wave: Wave, x: number, y: number) {

        super(scene.matter.world, x, y, "texture_atlas_1", "balon_extra0001", { shape: "circle", isStatic: true});

        this.wave = wave;
        this.name = GameConstants.EXTRA_BALL;
        this.deactivated = false;
        this.disappearing = false;

        this.setCircle(22, null);
        this.setSensor(true);
        this.setStatic(true);
        this.setCollisionCategory(GameConstants.PEGS_COLLISION_CATEGORY);
        this.setCollidesWith(GameConstants.BALLS_COLLISION_CATEGORY);

        this.play("extra-ball-spin");
    
        // HAY Q HACER ESTO PQ EL METODO UPDATE NO SE UTILIZA DE MANERA AUTOMATICA
        this.scene.sys.updateList.add(this);
    }

    public preUpdate(time: number, delta: number): void {

        super.preUpdate(time, delta);

        if (GameVars.placingExtraForwards) {
            return;
        }

        const cameraLimits = GameManager.getCameraLimits();

        if (this.y > cameraLimits.bottomY) {
            StageContainer.currentInstance.removeItem(this);
            return;
        }
    }

    public deactivate(): void {

        if (!this.scene) {
            return;
        }
        
        this.deactivated = true;
        
        this.alpha = GameConstants.ALPHA_DEACTIVATED_ITEMS;

        this.setCollidesWith([]);
    }

    public hit(): void {
        
        this.disappearing = true;

        this.anims.stop();
        this.setFrame("balon_extra0008");

        this.scene.tweens.add({
            targets: this,
            alpha: 0,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 350,
            delay: 750,
            onComplete: function(): void {
                StageContainer.currentInstance.removeItem(this);
            },
            onCompleteScope: this
        });
    }
}
