import { GameManager } from "../../GameManager";

export class DevLevelButton extends Phaser.GameObjects.Container {

    private level: number;
    private isEndless: boolean;
    private difficulty: string;

    constructor(scene: Phaser.Scene, level: number, isEndless?: boolean, difficulty?: string) {

        super(scene);

        this.level = level;
        this.isEndless = isEndless || false;
        this.difficulty = difficulty || null;

        this.setScale(.75);

        const btn = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_dev_level_selection_off");
        btn.setOrigin(.5);
        btn.setInteractive();
        btn.on("pointerover", function (): void {
            btn.setTexture("texture_atlas_1", "btn_dev_level_selection_on");
        }, this);
        btn.on("pointerout", function (): void {
            btn.setTexture("texture_atlas_1", "btn_dev_level_selection_off");
        }, this);
        btn.on("pointerdown", this.onClick, this);
        this.add(btn);

        const levelLabel = new Phaser.GameObjects.Text(this.scene, 0, 0, this.level.toString(), { fontFamily: "Arial", fontSize: "20px", color: "#f0faf9"});
        levelLabel.setOrigin(.5);
        levelLabel.setShadow(2, 2, "#38536f", .5);
        this.add(levelLabel);
    }

    private onClick(): void {
        
        GameManager.enterFieldScene(this.level, null, this.isEndless, this.difficulty);
    }
}

