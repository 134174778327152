import { Goal } from "../physics/Goal";
import { Goalkeeper } from "../physics/Goalkeeper";
import { GameVars } from "../../GameVars";
import { GameConstants } from "../../GameConstants";
import { ElectricPeg } from "../physics/ElectricPeg";
import { StarsEmitter } from "../physics/StarsEmitter";
import { Peg } from "../physics/Peg";
import { Forward } from "../physics/Forward";
import { Bumper } from "../physics/Bumper";
import { RivalForward } from "../physics/RivalForward";
import { MovingPlatform } from "../physics/MovingPlatform";
import { ExtraBall } from "../physics/ExtraBall";
import { WallGate } from "../physics/WallGate";
import { FieldManager } from "./FieldManager";

export class Wave {

    public static readonly DY = 50;

    private static endlessLevelLabelRightPosition = true;

    public waveHeight: number;
    public ballContainer: Phaser.GameObjects.Container;
    public wallGates: WallGate[];
    public name: string;
    
    private scene: Phaser.Scene;
    private stageContainer: any;
    private py: number;
    private items: WaveItem[];
   
    constructor(scene: Phaser.Scene, py: number, stageContainer: any) {

        this.scene = scene;
        this.py = py;
        this.stageContainer = stageContainer;
        this.items = [];
        this.name = FieldManager.waveFrameKey;

        let movingOwnPlayers = [];
        let movingItems = [];

        // TODO: arreglar un poco esto
        let deltaY = 0;

        this.addWallGates(deltaY + this.py);

        let x: number;
        let rightMovingPlatform = false;
        let leftMovingPlatform = false;

        for (let i = GameVars.waveRows.length - 1; i >= 0; i --) {

            let row = GameVars.waveRows[i];
            
            for (let j = 1; j < row.length  - 1; j ++) {

                x = GameConstants.FIELD_WIDTH / 15 * (j - .5);
                this.waveHeight = deltaY - (GameVars.waveRows.length - i) * Wave.DY + this.py;

                if (row[j] === 1) {
                
                    const electricPeg = new ElectricPeg(scene, this, x, this.waveHeight, false);
                    this.stageContainer.addItem(electricPeg, true);

                    this.items.push(electricPeg);
                } 

                if (row[j] === 7) {
                    
                    const electricPeg = new ElectricPeg(scene, this, x, this.waveHeight, true);
                    this.stageContainer.addItem(electricPeg, true);

                    this.items.push(electricPeg);
                } 

                if (row[j] === 20) {
                    
                    const electricPeg = new ElectricPeg(scene, this, x, this.waveHeight, false, false, true);
                    this.stageContainer.addItem(electricPeg);

                    this.items.push(electricPeg);
                } 

                if (row[j] === 3) {
                    
                    const starsEmitter = new StarsEmitter(scene, this, x, this.waveHeight);
                    this.stageContainer.addItem(starsEmitter);

                    this.items.push(starsEmitter);
                } 

                if (row[j] === 5) {
                    
                    const peg = new Peg(scene, this, x, this.waveHeight, 1);
                    this.stageContainer.addItem(peg);

                    this.items.push(peg);
                }

                if (row[j] === 4) {
                    
                    const peg = new Peg(scene, this, x, this.waveHeight, 2);
                    this.stageContainer.addItem(peg);

                    this.items.push(peg);
                }

                if (row[j] === 11) {
                    
                    const peg = new Peg(scene, this, x, this.waveHeight, 3);
                    this.stageContainer.addItem(peg);

                    this.items.push(peg);
                } 
                
                if (row[j] === 6) {
                    
                    const peg = new Peg(scene, this, x, this.waveHeight, -1);
                    this.stageContainer.addItem(peg);

                    this.items.push(peg);
                } 

                if (row[j] === 8) {
                    
                    const forward = new Forward(scene, this, x, this.waveHeight);
                    this.stageContainer.addItem(forward);

                    movingOwnPlayers.push(forward);

                    this.items.push(forward);
                } 

                if (row[j] === 9) {
                    
                    const forward = new Forward(scene, this, x, this.waveHeight, true);
                    this.stageContainer.addItem(forward);

                    movingOwnPlayers.push(forward);

                    this.items.push(forward);
                } 

                if (row[j] === 10) {
                    
                    const bumper = new Bumper(scene, this, x, this.waveHeight, false);
                    this.stageContainer.addItem(bumper);

                    this.items.push(bumper);
                } 

                if (row[j] === 12) {
                    
                    const rivalForward = new RivalForward(scene, this, x, this.waveHeight, false);
                    this.stageContainer.addItem(rivalForward);

                    movingItems.push(rivalForward);

                    this.items.push(rivalForward);
                } 

                if (row[j] === 13) {
                    
                    const bumper = new Bumper(scene, this, x, this.waveHeight, true);
                    this.stageContainer.addItem(bumper);

                    movingOwnPlayers.push(bumper);

                    this.items.push(bumper);
                } 

                if (row[j] === 14) {
                    
                    if (rightMovingPlatform) {

                        rightMovingPlatform = false;

                        const movingPlatform = new MovingPlatform(scene, this, x - GameConstants.FIELD_WIDTH / 15 * .5, this.waveHeight, true);
                        this.stageContainer.addItem(movingPlatform);
                        movingItems.push(movingPlatform);

                        this.items.push(movingPlatform);

                    } else {
                        rightMovingPlatform = true;
                    }
                } 

                if (row[j] === 15) {

                    if (leftMovingPlatform) {

                        leftMovingPlatform = false;
                        const movingPlatform = new MovingPlatform(scene, this, x - GameConstants.FIELD_WIDTH / 15 * .5, this.waveHeight, false);
                        this.stageContainer.addItem(movingPlatform);
                        movingItems.push(movingPlatform);

                        this.items.push(movingPlatform);

                    } else {
                        leftMovingPlatform = true;
                    }
                } 

                if (row[j] === 17) {

                    const extraBall = new ExtraBall(scene, this, x, this.waveHeight);
                    this.stageContainer.addItem(extraBall);

                    this.items.push(extraBall);
                }

                if (row[j] === 18) {
                
                    const electricPegSwitch = new ElectricPeg(scene, this, x, this.waveHeight, false, true);
                    this.stageContainer.addItem(electricPegSwitch);

                    this.items.push(electricPegSwitch);
                } 

                if (row[j] === 19) {
                    
                    const forward = new Forward(scene, this, x, this.waveHeight, false, false, true);
                    this.stageContainer.addItem(forward);

                    this.items.push(forward);
                } 
                
            }
        }

        for (let i = 0; i < movingOwnPlayers.length; i ++) {

            this.stageContainer.bringToTop(movingOwnPlayers[i]);
        }

        for (let i = 0; i < movingItems.length; i ++) {
            
            this.stageContainer.bringToTop(movingItems[i]);
        }

        if (GameConstants.DEVELOPMENT || GameVars.editingLevels) {
            this.addMark();
        }
    }

    public removeItem(item: WaveItem): void {

        const i = this.items.indexOf(item);

        if (i !== -1) {
            this.items.splice(i, 1);
        }
    }

    public onContinue(kickOffForwardY: number): void {
 
        for (let i = 0; i < this.items.length; i ++) {

            const item = this.items[i];

            let y: number;

            if (item.name === GameConstants.ELECTRIC_PEG) {
                y = (item as ElectricPeg).pegBody.y;
            } else {
                y = item.y;
            }

            if (!item.deactivated && y > kickOffForwardY - GameConstants.DEACTIVATED_ITEMS_DELTA_Y) {
                item.deactivate();
            }
        }
    }

    public removeWallGate(wallGate: WallGate): void {

        const i = this.wallGates.indexOf(wallGate);

        if (i !== -1) {
            this.wallGates.splice(i, 1);
        }
    }

    private addWallGates(deltaY: number): void {

        this.wallGates = [];
        
        // la pared izquierda
        let newGate = false;
        let length = 0;

        for (let i = 0; i < GameVars.waveRows.length; i ++) {

            if (GameVars.waveRows[i][0] === 2 && !newGate) {
                newGate = true;
            }

            if (newGate && GameVars.waveRows[i][0] === 2) {
                length ++;
            } else if (newGate && GameVars.waveRows[i][0] === 3) {
                
                const py = deltaY - (GameVars.waveRows.length - i + length) * Wave.DY;
                const wallGate = new WallGate(this.scene, GameConstants.LEFT, py, length, this);
                this.stageContainer.addItem(wallGate);

                this.wallGates.push(wallGate);

                newGate = false;
                length = 0;
            }
        }

        // la pared derecha
        const rigtWallIndex = GameVars.waveRows[0].length - 1;
        for (let i = 0; i < GameVars.waveRows.length; i ++) {

            if (GameVars.waveRows[i][rigtWallIndex] === 2 && !newGate) {
                newGate = true;
            }

            if (newGate && GameVars.waveRows[i][rigtWallIndex] === 2) {
                length ++;
            } else if (newGate && GameVars.waveRows[i][rigtWallIndex] === 3) {
                
                const py = deltaY - (GameVars.waveRows.length - i + length) * Wave.DY;
                const wallGate = new WallGate(this.scene, GameConstants.RIGHT, py, length, this);
                this.stageContainer.addItem(wallGate);

                this.wallGates.push(wallGate);

                newGate = false;
                length = 0;
            }
        }

        if (this.wallGates.length > 0) {
            // ordenarlos segun la Y
            this.wallGates.sort((w1: WallGate, w2: WallGate) => 
               w1.y - w2.y
            );
        }
    }

    private addMark(): void {

        const line = new Phaser.GameObjects.Graphics(this.scene);
        line.lineStyle(1, 0xFF0000);
        line.moveTo(0, this.waveHeight);
        line.lineTo(GameConstants.GAME_WIDTH, this.waveHeight);
        line.stroke();

        this.stageContainer.addItem(line, false, true);

        let labelStr = FieldManager.waveFrameKey;

        if (GameVars.mirrorPegs) {
            labelStr += "-mirrored";
        }

        const levelLabelBottom = new Phaser.GameObjects.Text(this.scene, 10, this.waveHeight + 5, labelStr, {fontFamily: "OswaldDemibold", fontSize: "20px", color: "#0000FF"});
        this.stageContainer.addItem(levelLabelBottom, false, true);

        const levelLabelTop = new Phaser.GameObjects.Text(this.scene, 10, this.py - 28, labelStr, {fontFamily: "OswaldDemibold", fontSize: "20px", color: "#FFFF00"});
        this.stageContainer.addItem(levelLabelTop, false, true);
        
        Wave.endlessLevelLabelRightPosition = !Wave.endlessLevelLabelRightPosition;
    }
}
