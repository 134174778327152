import { ExtraForwardSelectionButton } from "./ExtraForwardSelectionButton";
import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";

export class ExtraForwardSelectionButtonsContainer extends Phaser.GameObjects.Container {

    public static currentInstance: ExtraForwardSelectionButtonsContainer;

    private buttons: ExtraForwardSelectionButton[];

    constructor(scene: Phaser.Scene) {

        super(scene);

        ExtraForwardSelectionButtonsContainer.currentInstance = this;

        this.x = GameConstants.GAME_WIDTH / 2;
        this.y = 95;

        this.buttons = [];

        const infoLabelBet = new Phaser.GameObjects.Text(this.scene, 0, -30, "extra forwards", {fontFamily: "Arial", fontSize: "24px", color: "#000000"});
        infoLabelBet.setOrigin(.5);
        this.add(infoLabelBet);

        const deltaButton = 50;

        for (let i = 0; i < 6; i ++) {
            const b = new ExtraForwardSelectionButton(this.scene, i);
            b.x = (-2.5 + i) * deltaButton;
            this.add(b);

            if (i === GameVars.devNumExtraForwards) {
                b.enableButton();
            }

            this.buttons.push(b);
        }
    }

    public onNumExtraForwardsSelected(): void {

        for (let i = 0; i < this.buttons.length; i++) {
            if (this.buttons[i].name !== GameVars.devNumExtraForwards.toString()) {
                this.buttons[i].disableButton();
            } 
        }
    }
}
