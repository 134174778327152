import { Ball } from "./scenes/physics/Ball";
import { GameConstants } from "./GameConstants";

export class GameVars {

    public static simplioApiUrl: string;
    public static simplioUserId: string;
    public static simplioGameId: string;
    public static simplio: any;

    public static intitialised: boolean;
    public static gameData: GameData;
    public static scaleY: number; 
    public static stageDisplayMode: string;
    public static currentScene: Phaser.Scene;
    public static gameOver: boolean;
    public static gameOverCause: string;
    public static waveRows: number[][];
    public static scrolling: boolean;
    public static placingExtraForwards: boolean;
    public static canvasTextures: any;
    public static difficulty: string;
    public static firstWave: boolean;
    public static coinsFrenzy_py: number;
   
    // lo relativo al modo endless
    public static dMarker: number;
    public static stars: number;
    public static currentEndlessLevel: number;
    public static newRecord: boolean;
    public static mirrorPegs: boolean;
    public static firstEndlessLevel: number;
    public static continued: boolean;

    public static showTournamentLayer: boolean;

    // lo relativo a modos de desarrollo
    public static editingLevels: boolean;
    public static screenshotMode: boolean;
    public static devNumExtraForwards: number;
    public static previousDevNumExtraForwards: number;

    // lo relativo a los logros 
    public static starsBeforeContinue: number;
    public static metersBeforeContinue: number;
    public static kicks: number;
    public static pigHits: number;

    public static areValidDomains(domains: string[]): boolean {

        if (domains.length === 0) {
            return true;
        }

        const hostname = window.location.hostname;
        const referrer = document.referrer;

        let isValid = false;

        for (let i = 0; i < domains.length; i++) {
            if (hostname.indexOf(domains[i]) !== -1) {
                isValid = true;
                break;
            }
        }

        if (referrer) {

            isValid = false;
            
            for (let i = 0; i < domains.length; i++) {

                if (referrer.indexOf(domains[i]) !== -1) {
                    isValid = true;
                    break;
                }
            }
        }

        return isValid;
    }
    
    public static formatNumber(value: number): string {

        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    public static getCollidingItems(bodyA: any, bodyB: any): {ball: Ball, body: any} {

        let ball: Ball;
        let body: any;

        if (bodyA.gameObject.name === GameConstants.BALL)  {
            ball = <Ball> bodyA.gameObject;
            body = bodyB;
        }

        if (bodyB.gameObject.name === GameConstants.BALL)  {
            ball = <Ball> bodyB.gameObject;
            body = bodyA;
        }

        if (ball) {
            return {ball: ball, body: body};
        } else {
            return {ball: null, body: body};
        }
    }

    public static clamp(num: number, min: number, max: number): number {
        
        return num <= min ? min : num >= max ? max : num;
    }
}
