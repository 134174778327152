import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { Button } from "../../utils/Utils";
import { StageContainer } from "./StageContainer";
import { FieldScene } from "./FieldScene";
import { AudioButtonContainer } from "../AudioButtonContainer";

 export class GUI extends Phaser.GameObjects.Container {

    private exitButton: Button;
    private nextClicked: boolean;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        this.nextClicked = false;

        const interactiveBackground = new Phaser.GameObjects.Graphics(this.scene);
        interactiveBackground.fillStyle(0x303030, 0);
        interactiveBackground.fillRect(0, 0, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT);
        interactiveBackground.generateTexture("interactive-background");

        const iBackground = new Phaser.GameObjects.Image(this.scene, 0, 0, "interactive-background");
        iBackground.setOrigin(0);
        iBackground.setInteractive();
        iBackground.on("pointerdown", this.onDown, this);
        iBackground.setScrollFactor(0);
        this.add(iBackground);

        const audioButtonContainer = new AudioButtonContainer(this.scene);
        audioButtonContainer.x = GameConstants.GAME_WIDTH - 30;
        audioButtonContainer.y = 34 * GameVars.scaleY;
        audioButtonContainer.scaleY = GameVars.scaleY;
        this.add(audioButtonContainer);
    }

    public gameOver(): void {
        // de momento nada
    }

    public showNextButton(): void {

        // quitamos el boton de exit
        this.scene.tweens.add({
            targets: this.exitButton,
            alpha: 0,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 350,
            onComplete : function(): void {
                this.exitButton.visible = false;
            },
            onCompleteScope: this
        });

        const nextButton = new Button(this.scene, GameConstants.GAME_WIDTH / 2 + 200, GameConstants.GAME_HEIGHT / 2 + 220 * GameVars.scaleY, "texture_atlas_1", "btn_next_off", "btn_next_on", true);
        nextButton.visible = false;
        nextButton.alpha = 0;
        nextButton.scaleY = GameVars.scaleY;
        nextButton.setScrollFactor(0);
        nextButton.onDown(this.onClickNext, this);
        this.add(nextButton);

        this.scene.tweens.add({
            targets: nextButton ,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 450,
            delay: 8000,
            onStart: function(): void {
                nextButton.visible = true;
            },
            onStartScope: this
        });

        this.scene.tweens.add({
            targets: nextButton ,
            x: nextButton .x + 10,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 800,
            repeat: -1,
            yoyo: false
        });
    }

    private onClickNext(): void {

        if (this.nextClicked) {
            return;
        }

        this.nextClicked = true;
    }

    private onDown(): void {

        if (FieldScene.currentInstance.tournamentIntroLayer || GameVars.gameOver ) {
            return;
        }

        StageContainer.currentInstance.onDown();
    }
}

