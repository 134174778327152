import { GameManager } from "../../GameManager";
import { GUI } from "./GUI";
import { HUD } from "./HUD";
import { FieldManager } from "./FieldManager";
import { StageContainer } from "./StageContainer";
import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { GameOverLayer } from "./game-over-layer/GameOverLayer";
import { AudioManager } from "../../AudioManager";
import { TrophyIntroLayer } from "./TrophyIntroLayer";
import { BackgroundContainer } from "./BackgroundContainer";

export class FieldScene extends Phaser.Scene {

    public static SCROLL_SPEED = 1.25;
    public static DELTA_SCROLL_SPEED = .05;
    public static DELTA_DISTANCE = 2;

    public static currentInstance: FieldScene;

    public scrollSpeedFactor: number;
    public scrollSpeed: number;
    public tournamentIntroLayer: TrophyIntroLayer;

    private gui: GUI;
    private hud: HUD;
    private topElectricPegsContainer: Phaser.GameObjects.Container;
    private stageContainer: StageContainer;
    private increaseScrollSpeedFactorDistance: number;
    private lastCoinsRowHit: boolean;
    private gameOverLayer: GameOverLayer;
    
    constructor() {

        super("FieldScene");
    }

    public create(): void {

        FieldScene.currentInstance = this;

        GameManager.setCurrentScene(this);

        this.scrollSpeedFactor = 1;
        this.scrollSpeed = FieldScene.SCROLL_SPEED * this.scrollSpeedFactor;
        this.increaseScrollSpeedFactorDistance = FieldScene.DELTA_DISTANCE;
        this.lastCoinsRowHit = false;
        this.gameOverLayer = null;

        FieldManager.init();

        this.createAnimations();

        this.stageContainer = new StageContainer(this);
        this.add.existing(this.stageContainer);

        this.addTopElectricPegs();
    
        this.hud = new HUD(this);
        this.add.existing(this.hud);

        this.gui = new GUI(this);
        this.add.existing(this.gui);

        this.cameras.main.scrollY = - StageContainer.DELTA_Y_WAVE;

        if (GameConstants.SHOW_PHYSICS_BODIES) {
            this.matter.world.debugGraphic.scaleX = this.stageContainer.scaleX;
        } 

        if (GameVars.showTournamentLayer && !GameConstants.SHOW_SPLASH && !GameConstants.DEVELOPMENT && !GameVars.editingLevels) {
            this.showTournamentLayer();
        } else {
            this.tournamentIntroLayer = null;
        }

        if (GameVars.screenshotMode) {
           this.cameras.main.setBackgroundColor("rgba(255, 255, 255, 1)");
           this.topElectricPegsContainer.visible = false;
        }
        
        // el audio
        AudioManager.playSound("loop_endless", true);
    }

    public update(time: number, delta: number): void {
        
        super.update(time, delta);

        if (GameConstants.SHOW_PHYSICS_BODIES) {
            this.matter.world.debugGraphic.setPosition((GameConstants.GAME_WIDTH - GameConstants.FIELD_WIDTH) * .5, 0);
        }

        if (GameVars.scrolling && !GameVars.gameOver) {

            this.cameras.main.scrollY -= (this.scrollSpeed * this.scrollSpeedFactor);  

         
            const d = Math.floor(-(this.cameras.main.scrollY + StageContainer.DELTA_Y_WAVE) / GameConstants.PIXELS_TO_METERS_FACTOR * 10) / 10;
            
            if (d > this.increaseScrollSpeedFactorDistance && this.scrollSpeedFactor < 2.65) {

                this.increaseScrollSpeedFactorDistance += FieldScene.DELTA_DISTANCE;

                this.tweens.add({
                    targets: this,
                    scrollSpeedFactor: this.scrollSpeedFactor + FieldScene.DELTA_SCROLL_SPEED,
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 1250
                });

                AudioManager.increaseLoopRate();
            }
        }
    }

    public removeTournamentIntroLayer(): void {

        this.tournamentIntroLayer.destroy();
        this.tournamentIntroLayer = null;
    }

    public coinObtained(isLastRow?: boolean): void {

        if (isLastRow && !this.lastCoinsRowHit) {

            this.lastCoinsRowHit = true;

            this.tweens.add({
                targets: this,
                scrollSpeed: 2 * this.scrollSpeed,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 650,
                onComplete: function(): void {
                    this.coinsFrenzy = false;
                },
                onCompleteScope: this
            });
        }

        this.hud.updateStars();
    }

    public onLevelOver(): void {

        this.tweens.add({
            targets: this,
            scrollSpeed: 0,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 650,
            onComplete: function(): void {
                
                this.gui.gameOver();

                this.gameOverLayer = new GameOverLayer(this);
                this.add.existing(this.gameOverLayer);
            },
            onCompleteScope: this
        });

        this.hud.levelOver();

        AudioManager.playSound("loop_gameover", true);
    }

    public onContinue(): void {

        this.gameOverLayer.destroy();
        this.hud.onContinue();

        this.stageContainer.onContinue();

        AudioManager.playSound("loop_endless", true);
    }

    public onDistanceMarkerCrossed(): void {

        this.stageContainer.onDistanceMarkerCrossed();
        
        this.hud.updateStars();
    }

    public nextWave(): void {

        this.hud.nextWave();

        this.tweens.add({
            targets: this,
            scrollSpeed: 15,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 250,
        });
    }

    public onKickOffForwardReachedPosition(): void {

        this.tweens.add({
            targets: this,
            scrollSpeed: FieldScene.SCROLL_SPEED,
            ease: Phaser.Math.Easing.Cubic.In,
            duration: 200,
        });
    }

    public onElectricPegsSwitched(): void {

        this.cameras.main.shake(180, 0.01);
    }

    public stopScrolling(): void {

        BackgroundContainer.currentInstance.addSpectators();
        
        this.tweens.add({
            targets: this,
            scrollSpeed: 0,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 350
        });

        // abrir la camara
        // TODO: DECIDIR EL ZOOM DE LA CAMARA
        let zoomFactor: number;
        
        if (GameVars.stageDisplayMode === StageContainer.STAGE_WITH_VERTICAL_STRIPES) {
            zoomFactor = .825;
        } else {
            zoomFactor = .675;
        }

        this.tweens.add({
            targets:  this.cameras.main,
            zoom: zoomFactor,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 2500
        });
    }

    private showTournamentLayer(): void {

        GameVars.scrolling = false;

        this.tournamentIntroLayer = new TrophyIntroLayer(this);
        this.add.existing(this.tournamentIntroLayer);

        this.cameras.main.fadeIn(500, 33, 56, 68);
    }

    private createAnimations(): void {

        this.anims.create({ 
            key: "peg-irradiate", 
            frames: this.anims.generateFrameNames("texture_atlas_1", { prefix: "electric_peg_", start: 1, end: 18, zeroPad: 2}), 
            frameRate: 16,
            repeat: -1
        });

        this.anims.create({ 
            key: "peg-disappear", 
            frames: this.anims.generateFrameNames("texture_atlas_1", { prefix: "peg_disappear_", start: 1, end: 4, zeroPad: 4}), 
            frameRate: 24
        });

        this.anims.create({ 
            key: "coins-frenzy", 
            frames: this.anims.generateFrameNames("texture_atlas_1", { prefix: "coin_switch_", start: 1, end: 5, zeroPad: 2}), 
            frameRate: 12,
            repeat: -1
        });

        this.anims.create({ 
            key: "star-spin", 
            frames: this.anims.generateFrameNames("texture_atlas_1", { prefix: "star_points_", start: 1, end: 8, zeroPad: 1}), 
            frameRate: 16,
            repeat: -1
        });

        this.anims.create({ 
            key: "coin-fx", 
            frames: this.anims.generateFrameNames("texture_atlas_1", { prefix: "coin_fx_", start: 1, end: 14, zeroPad: 4}), 
            frameRate: 30,
        });

        this.anims.create({ 
            key: "extra-ball-spin", 
            frames: this.anims.generateFrameNames("texture_atlas_1", { prefix: "balon_extra", start: 1, end: 7, zeroPad: 4}), 
            frameRate: 24,
            repeat: -1
        });

        this.anims.create({ 
            key: "piggy-pulse", 
            frames: this.anims.generateFrameNames("texture_atlas_1", { prefix: "coin_emissor_", start: 1, end: 12, zeroPad: 2}), 
            frameRate: 16
        });

        this.anims.create({ 
            key: "piggy-pulse-plus", 
            frames: this.anims.generateFrameNames("texture_atlas_1", { prefix: "coin_emissor_plus_", start: 1, end: 12, zeroPad: 2}), 
            frameRate: 26,
            repeat: -1
        });

        this.anims.create({ 
            key: "switch", 
            frames: this.anims.generateFrameNames("texture_atlas_1", { prefix: "switch_", start: 1, end: 17, zeroPad: 2}), 
            frameRate: 16,
            repeat: -1
        });

        this.anims.create({ 
            key: "ball-confetti", 
            frames: this.anims.generateFrameNames("texture_atlas_2", { prefix: "ball_confetti_", start: 1, end: 12, zeroPad: 2}), 
            frameRate: 16,
            hideOnComplete: true,
        });
    }

    private addTopElectricPegs(): void {

        this.topElectricPegsContainer = this.add.container(StageContainer.currentInstance.x, 0);
        this.topElectricPegsContainer.scaleX = StageContainer.currentInstance.scaleX;
        this.topElectricPegsContainer.setScrollFactor(0);

        for (let i = 0; i < 15; i ++) {
            const electricPeg = this.add.sprite(GameConstants.FIELD_WIDTH / 15 * ( i + .5), 0, "texture_atlas_1", "electric_peg_01");
            this.topElectricPegsContainer.add(electricPeg);
            electricPeg.play("peg-irradiate");
            const numFrames = electricPeg.anims.currentAnim.frames.length;
            const startFrame = electricPeg.anims.currentAnim.frames[Math.floor(numFrames * Math.random())];
            electricPeg.anims.setCurrentFrame(startFrame);
        }
    }
}
