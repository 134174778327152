import { Ball } from "./Ball";
import { GameConstants } from "../../GameConstants";
import { Wave } from "../field/Wave";
import { GameVars } from "../../GameVars";
import { StageContainer } from "../field/StageContainer";
import { GameManager } from "../../GameManager";
import { AudioManager } from "../../AudioManager";

export class Forward extends Phaser.GameObjects.Container implements WaveItem {

    public static BALL_SHOOTING_SPEED = 25;
    public static id = 0;

    private static ROTATION_TWEEN_MIDDLE =  [{"r": 0.0959}, {"r":  0.1868}, {"r":  0.2726}, {"r": 0.3534}, {"r": 0.4291}, {"r": 0.4998}, {"r": 0.5654}, {"r": 0.626}, {"r": 0.6816}, {"r": 0.732}, {"r": 0.7775}, {"r": 0.8179}, {"r": 0.8532}, {"r": 0.8835}, {"r": 0.9088}, {"r": 0.929}, {"r": 0.9441}, {"r": 0.9542}, {"r": 0.9592}, {"r": 0.9599}, {"r": 0.9574}, {"r": 0.9498}, {"r": 0.9372}, {"r": 0.9195}, {"r": 0.8968}, {"r": 0.869}, {"r": 0.8362}, {"r": 0.7983}, {"r": 0.7554}, {"r": 0.7074}, {"r": 0.6544}, {"r": 0.5964}, {"r": 0.5332}, {"r": 0.4651}, {"r": 0.3919}, {"r": 0.3136}, {"r": 0.2303}, {"r": 0.142}, {"r": 0.0485}, {"r": 0}, {"r": -0.096}, {"r": -0.1869}, {"r": -0.2727}, {"r": -0.3535}, {"r": -0.4292}, {"r": -0.4999}, {"r": -0.5655}, {"r": -0.6261}, {"r": -0.6817}, {"r": -0.7321}, {"r": -0.7776}, {"r": -0.818}, {"r": -0.8533}, {"r": -0.8836}, {"r": -0.9089}, {"r": -0.9291}, {"r": -0.9442}, {"r": -0.9543}, {"r": -0.9593}, {"r": -0.96}, {"r": -0.9575}, {"r": -0.9499}, {"r": -0.9373}, {"r": -0.9196}, {"r": -0.8969}, {"r": -0.8691}, {"r": -0.8363}, {"r": -0.7984}, {"r": -0.7555}, {"r": -0.7075}, {"r": -0.6545}, {"r": -0.5965}, {"r": -0.5333}, {"r": -0.4652}, {"r": -0.392}, {"r": -0.3137}, {"r": -0.2304}, {"r": -0.1421}, {"r": -0.0486}, {"r": 0}];
    private static ROTATION_TWEEN_RIGHT = [{"r": -0.0796}, {"r": -0.157}, {"r": -0.2324}, {"r": -0.3057}, {"r": -0.377}, {"r": -0.4462}, {"r": -0.5133}, {"r": -0.5784}, {"r": -0.6414}, {"r": -0.7023}, {"r": -0.7612}, {"r": -0.818}, {"r": -0.8727}, {"r": -0.9254}, {"r": -0.976}, {"r": -1.0245}, {"r": -1.071}, {"r": -1.1154}, {"r": -1.1578}, {"r": -1.198}, {"r": -1.2362}, {"r": -1.2724}, {"r": -1.3065}, {"r": -1.3385}, {"r": -1.3684}, {"r": -1.3963}, {"r": -1.4221}, {"r": -1.4459}, {"r": -1.4676}, {"r": -1.4872}, {"r": -1.5048}, {"r": -1.5202}, {"r": -1.5337}, {"r": -1.545}, {"r": -1.5543}, {"r": -1.5616}, {"r": -1.5667}, {"r": -1.5698}, {"r": -1.5708}, {"r": -1.5708}, {"r": -1.4913}, {"r": -1.4139}, {"r": -1.3385}, {"r": -1.2652}, {"r": -1.1939}, {"r": -1.1247}, {"r": -1.0576}, {"r": -0.9925}, {"r": -0.9295}, {"r": -0.8686}, {"r": -0.8097}, {"r": -0.7529}, {"r": -0.6982}, {"r": -0.6455}, {"r": -0.5949}, {"r": -0.5464}, {"r": -0.4999}, {"r": -0.4555}, {"r": -0.4131}, {"r": -0.3729}, {"r": -0.3347}, {"r": -0.2985}, {"r": -0.2644}, {"r": -0.2324}, {"r": -0.2025}, {"r": -0.1746}, {"r": -0.1488}, {"r": -0.125}, {"r": -0.1033}, {"r": -0.0837}, {"r": -0.0661}, {"r": -0.0507}, {"r": -0.0372}, {"r": -0.0259}, {"r": -0.0166}, {"r": -0.0093}, {"r": -0.0042}, {"r": -0.0011}, {"r": 0}, {"r": 0}];

    public deactivated: boolean;
    public forward: Phaser.GameObjects.Image;
    public hasBallAttached: boolean;
    public ball: Ball;
    public canBallBeAttached: boolean;
    public sensor: any;
    public caged: boolean;
    public wave: Wave;
    
    private f: number;
    private i: number;
    private speed: number;
    private moves: boolean;
    private isExtra: boolean;
    private leftLimit: number;
    private rightLimit: number;
    private cageLeftImg: Phaser.GameObjects.Image;
    private cageRightImg: Phaser.GameObjects.Image;
    private leftAngularLimit: number;
    private rigthAngularLimit: number;
    private idleTextureFrame: string;
    private shootingTextureFrame: string;
    private withBallTextureFrame: string;
    private tweenData: {r: number}[];
    private openCageNextFrame: boolean;
   
    constructor(scene: Phaser.Scene, wave: Wave, x: number, y: number, moves?: boolean, isExtra?: boolean, caged?: boolean) {
        
        super(scene);

        Forward.id ++;
    
        this.wave = wave;
        this.x = x;
        this.y = y;

        this.deactivated = false;
        this.moves = moves || false;
        this.hasBallAttached = false;
        this.canBallBeAttached = true;
        this.moves = moves || false;
        this.isExtra = isExtra || false;
        this.caged = caged || false;
        this.openCageNextFrame = false;
        this.speed = .75;
        
        if (Forward.id % 2 === 0) {
            this.speed *= -1;
        }

        this.f = 0;
        this.i = 0;

        if (this.isExtra) {
            const marker = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "extra_mark");
            this.add(marker);
        }

        const playerID = 31;

        this.idleTextureFrame = "delantero_" + playerID + "_01";
        this.shootingTextureFrame = "delantero_" + playerID + "_03";
        this.withBallTextureFrame = "delantero_" + playerID + "_02";

        this.forward = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", this.idleTextureFrame);
        this.add(this.forward);

        if (this.x > GameConstants.FIELD_WIDTH * 3 / 4 ) {

            this.tweenData = Forward.ROTATION_TWEEN_RIGHT;
            this.leftAngularLimit = -1.5708;
            this.rigthAngularLimit = 0;

        } else if (this.x < GameConstants.FIELD_WIDTH / 4 ) {

            this.tweenData = [];

            for (let i = 0; i < Forward.ROTATION_TWEEN_RIGHT.length; i ++) {
                this.tweenData[i] = {r: -Forward.ROTATION_TWEEN_RIGHT[i].r};
            }
            
            this.leftAngularLimit = 0; 
            this.rigthAngularLimit = 1.5707; 

        } else {

            this.tweenData = Forward.ROTATION_TWEEN_MIDDLE;
            this.leftAngularLimit = -0.96;
            this.rigthAngularLimit = 0.9599;    
        }

        if (this.moves) {

            this.leftLimit = this.x - 60;
            this.rightLimit = this.x + 60;

            if (this.leftLimit < Wave.DY / 2) {
                this.leftLimit = Wave.DY / 2;
            }

            if (this.rightLimit > GameConstants.FIELD_WIDTH - Wave.DY / 2) {
                this.rightLimit = GameConstants.FIELD_WIDTH - Wave.DY / 2;
            }

        } else {

            this.leftLimit = null;
            this.rightLimit = null;
        }

        if (this.caged) {

            this.addCage();

            this.sensor = this.scene.matter.add.circle(x, y, 50, {isSensor: false, isStatic: true}, 5);

        } else {

            this.cageLeftImg = null;
            this.cageRightImg = null;

            this.sensor = this.scene.matter.add.circle(x, y, 38, {isSensor: true, isStatic: false}, 5);
        }

        this.sensor.parent.gameObject = this;
        this.sensor.ignoreGravity = true;
        this.sensor.name = GameConstants.FORWARD;

        this.sensor.collisionFilter.category = GameConstants.PEGS_COLLISION_CATEGORY;
        this.sensor.collisionFilter.mask = GameConstants.BALLS_COLLISION_CATEGORY;

        // HAY Q HACER ESTO PQ EL METODO UPDATE NO SE UTILIZA DE MANERA AUTOMATICA
        this.scene.sys.updateList.add(this);
    }

    public preUpdate(time: number, delta: number): void {

        if (!GameVars.placingExtraForwards) {

            const cameraLimits = GameManager.getCameraLimits();

            if (this.y > cameraLimits.bottomY) {
                this.scene.matter.world.remove(this.sensor, true);
                StageContainer.currentInstance.removeItem(this);
                return;
            }
        }

        if (!this.canBallBeAttached) {

            this.f ++;

            if (this.f === 8) {
                this.canBallBeAttached = true;
                this.forward.setFrame(this.idleTextureFrame);
            }
        }
        
        if (this.hasBallAttached && this.ball.active && !GameVars.gameOver) {

            const rot = this.tweenData[this.i].r;

            this.rotation = rot;
            this.i = this.i < this.tweenData.length - 1 ? this.i + 1 : 0;

            this.ball.setVelocity(0, 0);
            
            this.ball.x = this.x + 34 * Math.sin(this.rotation);
            this.ball.y = this.y - 34 * Math.cos(this.rotation);
            this.ball.rotation = this.rotation;

            if (rot === this.leftAngularLimit) {
                AudioManager.playSound("forward_back");
            } 
            
            if (rot === this.rigthAngularLimit) {
                AudioManager.playSound("forward_forth");
            }
        }

        if (this.moves) {

            this.x += this.speed;
            this.sensor.position.x = this.x;

            if (this.x >= this.rightLimit || this.x <= this.leftLimit) {
                this.speed *= -1;
            }
        }

        if (this.openCageNextFrame) {

            this.f ++;

            if (this.f > 2) {
                this.openCageNextFrame = false;
                this.caged = false;
            }
        }
    }

    public deactivate(): void {

        if (!this.scene) {
            return;
        }
        
        this.deactivated = true;

        this.alpha = GameConstants.ALPHA_DEACTIVATED_ITEMS;

        this.sensor.collisionFilter.mask = 0;
    }

    public onBallStolen(): void {

        this.ball = null;
        this.canBallBeAttached = true;
        this.hasBallAttached = false;

        this.forward.setFrame(this.idleTextureFrame);
    }

    public attach(ball: Ball): void {

        this.ball = ball;
        this.ball.attachToForward(this);

        this.hasBallAttached = true;

        this.forward.setFrame(this.withBallTextureFrame);

        AudioManager.playSound("atrapa_chutador");
    }

    public shoot(): void {

        if (!this.ball) {
            return;
        }

        this.f = 0;
        this.canBallBeAttached = false;
        this.hasBallAttached = false;
        
        this.forward.setFrame(this.shootingTextureFrame);

        this.ball.shotByForward();

        this.ball = null;

        AudioManager.playSound("chuta_chutador");
    }

    public openCage(): void {

        this.openCageNextFrame = true;
        this.f = 0;

        // destruimos el sensor de la jaula y creamos otro para el jugador
        this.scene.matter.world.remove(this.sensor, true);

        this.sensor = this.scene.matter.add.circle(this.x, this.y, 30, {isSensor: true, isStatic: false}, 5);

        this.sensor.parent.gameObject = this;
        this.sensor.ignoreGravity = true;
        this.sensor.name = GameConstants.FORWARD;

        this.sensor.collisionFilter.category = GameConstants.PEGS_COLLISION_CATEGORY;
        this.sensor.collisionFilter.mask = GameConstants.BALLS_COLLISION_CATEGORY;

        this.scene.tweens.add({
            targets: this.cageLeftImg,
            x: -15,
            y: 75,
            angle: -20,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });

        this.scene.tweens.add({
            targets: this.cageLeftImg,
            alpha: 0,
            ease: Phaser.Math.Easing.Cubic.Out,
            delay: 200,
            duration: 300,
            onComplete: function(): void {
                this.cageLeftImg.destroy();
            },
            onCompleteScope: this
        });

        this.scene.tweens.add({
            targets: this.cageRightImg,
            x: 15,
            y: 75,
            angle: 20,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });

        this.scene.tweens.add({
            targets: this.cageRightImg,
            alpha: 0,
            ease: Phaser.Math.Easing.Cubic.Out,
            delay: 200,
            duration: 300,
            onComplete: function(): void {
                this.cageRightImg.destroy();
            },
            onCompleteScope: this
        });

        AudioManager.playSound("switch");
    }

    private addCage(): void {
        
        this.cageLeftImg = new Phaser.GameObjects.Image(this.scene, 0, 50, "texture_atlas_1", "cageLeft");  
        this.cageLeftImg.setOrigin(1);
        this.add(this.cageLeftImg);
        
        this.cageRightImg = new Phaser.GameObjects.Image(this.scene, 0, 50, "texture_atlas_1", "cageLeft"); 
        this.cageRightImg.setOrigin(1); 
        this.cageRightImg.scaleX = -1;
        this.add(this.cageRightImg);
    }
}

