import { Button } from "../../utils/Utils";
import { GameManager } from "../../GameManager";
import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { DevLevelButton } from "./DevLevelButton";
import { AudioManager } from "../../AudioManager";
import { ExtraForwardSelectionButtonsContainer } from "./ExtraForwardSelectionButtonsContainer";

export class DevelopmentLevelSelectionScene extends Phaser.Scene {

    public static currentInstance: DevelopmentLevelSelectionScene;
    public static ENDLESS = "endless";
    public static STORY = "story";

    private static mode = DevelopmentLevelSelectionScene.ENDLESS;

    private page = 1;
    private levelsRail: Phaser.GameObjects.Container;
    private arrowButtonLevelTypes: Button;
    private screenshotModeButtonOff: Button;
    private screenshotModeButtonOn: Button;
    private arrowButtonUp: Phaser.GameObjects.Image;
    private arrowButtonDown: Phaser.GameObjects.Image;
    private titleLabel: Phaser.GameObjects.Text;
    private extraForwardSelectionButtonsContainer: ExtraForwardSelectionButtonsContainer;
    
    constructor() {

        super("DevelopmentLevelSelectionScene");
    }

    public create(): void {

        DevelopmentLevelSelectionScene.currentInstance = this;

        GameManager.setCurrentScene(this);

        const background = this.add.graphics();
        background.fillStyle(0xFFA500);
        background.fillRect(0, 0, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT);

        this.levelsRail = this.add.container(0, 0);
        this.levelsRail.scaleY = GameVars.scaleY;

        if (this.page === 2) {
            this.levelsRail.y = -550;
        } else if (this.page === 3) {
            this.levelsRail.y = -1000;
        }

        if (DevelopmentLevelSelectionScene.mode === DevelopmentLevelSelectionScene.ENDLESS) {
            this.levelsRail.x = -GameConstants.GAME_WIDTH;
        }

        // LOS NIVELES NORMALES
        let n: number;

        for (let i = 0; i < 10; i++) {
            for (let j = 0; j < 18; j++) {

                n = 10 * j + i;

                if (n <=  89) {
                    let btn = new DevLevelButton(this, n);
                    btn.x = GameConstants.GAME_WIDTH / 2 + (i - 4.5) * 60;
                    btn.y = (190 + 50 * j) / GameVars.scaleY;
                    this.levelsRail.add(btn);
                }
            }
        }

        // LOS NIVELES ENDLESS
        const easyLabel = this.add.text(GameConstants.GAME_WIDTH * 3 / 2, 160 * GameVars.scaleY, "easy", { font: "20px Arial", fill: "#000000"});
        easyLabel.setOrigin(.5);
        easyLabel.scaleY = GameVars.scaleY;
        this.levelsRail.add(easyLabel);

        for (let i = 0; i < 9; i++) {
            for (let j = 0; j < 8; j++) {

                n = 9 * j + i;

                if (n < GameConstants.NUM_EASY_ENDLESS) {
                    let btn = new DevLevelButton(this, n, true, GameConstants.ENDLESS_EASY);
                    btn.x = GameConstants.GAME_WIDTH * 3 / 2 + (i - 4) * 60;
                    btn.y = (210 + 50 * j) / GameVars.scaleY;
                    this.levelsRail.add(btn);
                }
            }
        }

        const mediumLabel = this.add.text(GameConstants.GAME_WIDTH * 3 / 2, 470 * GameVars.scaleY, "medium", { font: "20px Arial", fill: "#000000"});
        mediumLabel.setOrigin(.5);
        mediumLabel.scaleY = GameVars.scaleY;
        this.levelsRail.add(mediumLabel);

        for (let i = 0; i < 9; i++) {
            for (let j = 0; j < 4; j++) {

                n = 9 * j + i;

                if (n < GameConstants.NUM_MEDIUM_ENDLESS) {
                    let btn = new DevLevelButton(this, n, true, GameConstants.ENDLESS_MEDIUM);
                    btn.x = GameConstants.GAME_WIDTH * 3 / 2 + (i - 4) * 60;
                    btn.y = (520 + 50 * j) / GameVars.scaleY;
                    this.levelsRail.add(btn);
                }
            }
        }

        const hardLabel = this.add.text(GameConstants.GAME_WIDTH * 3 / 2, 720 * GameVars.scaleY, "hard", { font: "20px Arial", fill: "#000000"});
        hardLabel.setOrigin(.5);
        hardLabel.scaleY = GameVars.scaleY;
        this.levelsRail.add(hardLabel);

        for (let i = 0; i < 9; i++) {
            for (let j = 0; j < 5; j++) {

                n = 9 * j + i;

                if (n < GameConstants.NUM_HARD_ENDLESS) {
                    let btn = new DevLevelButton(this, n, true, GameConstants.ENDLESS_HARD);
                    btn.x = GameConstants.GAME_WIDTH * 3 / 2 + (i - 4) * 60;
                    btn.y = (770 + 50 * j) / GameVars.scaleY;
                    this.levelsRail.add(btn);
                }
            }
        }

        const headerBackground = this.add.graphics();
        headerBackground.fillStyle(0xFFFFFF);
        headerBackground.fillRect(0, 0, GameConstants.GAME_WIDTH, 140 * GameVars.scaleY);
        headerBackground.setInteractive();
        headerBackground.on("pointerdown", function(): void {
            //
        }, this);

        let titleString = DevelopmentLevelSelectionScene.mode === DevelopmentLevelSelectionScene.STORY ? "LEVEL SELECTION" : "LEVEL SELECTION ENDLESS";

        this.titleLabel = this.add.text(GameConstants.GAME_WIDTH / 2, 10 * GameVars.scaleY, titleString, { font: "28px Arial", fill: "#9e56eb"});
        this.titleLabel.originX = .5;
        this.titleLabel.scaleY = GameVars.scaleY;
        this.titleLabel.setShadow(1, 1, "#38536f", .5);

        const mainMenuButton = new Button(this, 50, 50 * GameVars.scaleY, "texture_atlas_1", "btn_exit_off", "btn_exit_on", true);
        mainMenuButton.scaleY = GameVars.scaleY;
        mainMenuButton.onDown(this.onClickMainMenuButton, this);
        this.add.existing(mainMenuButton);

        this.screenshotModeButtonOff = new Button(this, 50, 120 * GameVars.scaleY, "texture_atlas_1", "btn_switch_off_off", "btn_switch_off_on", true);
        this.screenshotModeButtonOff.scaleY = GameVars.scaleY;
        this.screenshotModeButtonOff.onDown(this.onClickScreenshotButton, this);
        this.add.existing(this.screenshotModeButtonOff);

        this.screenshotModeButtonOn = new Button(this, 50, 120 * GameVars.scaleY, "texture_atlas_1", "btn_switch_on_off", "btn_switch_on_on", true);
        this.screenshotModeButtonOn.scaleY = GameVars.scaleY;
        this.screenshotModeButtonOn.onDown(this.onClickScreenshotButton, this);
        this.add.existing(this.screenshotModeButtonOn);

        this.screenshotModeButtonOff.visible = !GameVars.screenshotMode;
        this.screenshotModeButtonOn.visible = GameVars.screenshotMode;

        this.extraForwardSelectionButtonsContainer = new ExtraForwardSelectionButtonsContainer(this);
        this.extraForwardSelectionButtonsContainer.visible = DevelopmentLevelSelectionScene.mode === DevelopmentLevelSelectionScene.ENDLESS ? false : true;
        this.add.existing(this.extraForwardSelectionButtonsContainer);
        
        this.arrowButtonLevelTypes = new Button(this, GameConstants.GAME_WIDTH - 35, 35 * GameVars.scaleY, "texture_atlas_1", "btn_back_off", "btn_back_on");
        this.arrowButtonLevelTypes.scaleX = GameVars.scaleY;
        this.arrowButtonLevelTypes.scaleY = DevelopmentLevelSelectionScene.mode === DevelopmentLevelSelectionScene.STORY ? 1 : -1;
        this.arrowButtonLevelTypes.angle = -90;
        this.arrowButtonLevelTypes.onDown(this.onClickArrowButtonLevelTypes, this);
        this.add.existing(this.arrowButtonLevelTypes);

        this.arrowButtonUp = new Phaser.GameObjects.Image(this, GameConstants.GAME_WIDTH - 35, 105 * GameVars.scaleY, "texture_atlas_1", "btn_back_off");
        this.arrowButtonUp.scaleY = GameVars.scaleY;
        this.arrowButtonUp.setInteractive();
        this.arrowButtonUp.angle = 180;
        this.arrowButtonUp.on("pointerover", function (): void {
            this.arrowButtonUp.setTexture("texture_atlas_1", "btn_back_on");
        }, this);
        this.arrowButtonUp.on("pointerout", function (): void {
            this.arrowButtonUp.setTexture("texture_atlas_1", "btn_back_off");
        }, this);
        this.arrowButtonUp.on("pointerdown", this.onClickArrowButtonUp, this);
        this.add.existing(this.arrowButtonUp);

        this.arrowButtonDown = new Phaser.GameObjects.Image(this, GameConstants.GAME_WIDTH - 100, 105 * GameVars.scaleY, "texture_atlas_1", "btn_back_off");
        this.arrowButtonDown.scaleY = GameVars.scaleY;
        this.arrowButtonDown.setInteractive();
        this.arrowButtonDown.on("pointerover", function (): void {
            this.arrowButtonDown.setTexture("texture_atlas_1", "btn_back_on");
        }, this);
        this.arrowButtonDown.on("pointerout", function (): void {
            this.arrowButtonDown.setTexture("texture_atlas_1", "btn_back_off");
        }, this);
        this.arrowButtonDown.on("pointerdown", this.onClickArrowButtonDown, this);
        this.add.existing(this.arrowButtonDown);

        AudioManager.stopLoop();
    }

    private onClickArrowButtonLevelTypes(): void {
        
        this.arrowButtonLevelTypes.scaleY *= -1;

        let px: number;

        if (DevelopmentLevelSelectionScene.mode === DevelopmentLevelSelectionScene.STORY) {
            px = -GameConstants.GAME_WIDTH;
            this.titleLabel.text = "LEVEL SELECTION ENDLESS";
            this.extraForwardSelectionButtonsContainer.visible = false;
            DevelopmentLevelSelectionScene.mode = DevelopmentLevelSelectionScene.ENDLESS;
        } else {
            px = 0;
            this.titleLabel.text = "LEVEL SELECTION";
            this.extraForwardSelectionButtonsContainer.visible = true;
            DevelopmentLevelSelectionScene.mode = DevelopmentLevelSelectionScene.STORY;
        }

        this.tweens.add({
            targets: this.levelsRail,
            x: px,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });
    }

    private onClickArrowButtonUp(): void {
        
        let py: number;

        if (this.page === 2) {
            py = 0;
            this.page = 1;
        } else if (this.page === 3) {
            py = -550;
            this.page = 2;
        } else {
            return;
        }

        this.tweens.add({
            targets: this.levelsRail,
            y: py,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });
    }

    private onClickArrowButtonDown(): void {
        
        let py: number;

        if (this.page === 1) {
            py = -550;
            this.page = 2;
        } else if (this.page === 2) {
            py = -1000;
            this.page = 3;
        } else {
            return;
        }

        this.tweens.add({
            targets: this.levelsRail,
            y: py,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });
    }

    private onClickMainMenuButton(): void {
        
        GameManager.enterHomeScene();
    }

    private onClickScreenshotButton(): void {

        this.screenshotModeButtonOff.visible = !this.screenshotModeButtonOff.visible;
        this.screenshotModeButtonOn.visible = !this.screenshotModeButtonOn.visible;

        GameManager.switchScreenShotMode();
    }
}

