export class GameConstants {
        
    public static readonly VERSION = "1.0";

    public static readonly GAME_WIDTH = 600;
    public static readonly GAME_HEIGHT = 800;
    public static readonly FIELD_WIDTH = 480;
    public static readonly STAGE_CONTAINER_IPHONE_X_SCALE_FACTOR = .825;
    
    public static readonly SHOW_PHYSICS_BODIES = false;
    public static readonly EDITING_LEVELS = false;
    public static readonly FORCING_LEVEL_0 = true;
    public static readonly DEVELOPMENT = false;
    public static readonly SECRET_ACCESS_TO_LEVEL_EDITION = false;
    public static readonly SHOW_SPLASH = false;
    public static readonly VERBOSE = false;

    public static readonly SPONSOR_MINIJUEGOS = "minijuegos";
    public static readonly SPONSOR_GAMEPIX = "Gamepix";
    public static readonly SPONSOR_LAGGED = "lagged";
    public static readonly SPONSOR_NONE = "none";
    public static readonly SPONSOR: string = GameConstants.SPONSOR_NONE;

    public static readonly ALPHA_DEACTIVATED_ITEMS = .35;
    public static readonly DEACTIVATED_ITEMS_DELTA_Y = 425;
    public static readonly DELTA_METERS_MARKER = 10;
    public static readonly STARS_ON_CROSSED_MARKER = 10;

    public static readonly GAME_OVER_STOLEN_BALL = "rival stole ball";
    public static readonly GAME_OVER_ELECTRIC_PEG = "electric peg";
    public static readonly GAME_OVER_GOAL_ELECTRIC_PEG = "electric peg goal";
    public static readonly GAME_OVER_TOP_ELECTRIC_PEG = "electric peg top";
    public static readonly GAME_OVER_BALL_HIT_BOTTOM = "ball hit bottom";
    public static readonly GAME_OVER_KICKOFF_FORWARD_OUT_OF_SCREEN = "kick off forward out of screen";
    public static readonly GAME_OVER_GOALKEEPER = "goalkeeper";
    public static readonly GAME_OVER_FORWARD_OR_BUMPER_WITH_ATTACHED_BALL_OUT_OF_SCREEN = "bumper or forward with attached ball out of screen";

    public static readonly PRICE_EXTRA_FORWARD = 50;
    public static readonly PRICE_MOVE_EXTRA_FORWARD = 50;

    public static readonly LEVEL_TUTORIAL_EXTRA_FORWARD = 8;

    public static readonly PIXELS_TO_METERS_FACTOR = 100;
    public static readonly COINS_FRENZY_LENGTH = GameConstants.GAME_HEIGHT * .85;

    public static readonly ENDLESS_EASY = "endless_easy";
    public static readonly ENDLESS_MEDIUM = "endless_medium";
    public static readonly ENDLESS_HARD = "endless_hard";

    public static OCEANIA_CUP = "OCEANIA CUP";
    public static AFRICA_CUP = "AFRICA CUP";
    public static ASIAN_CUP = "ASIAN CUP";
    public static GOLD_CUP = "GOLD CUP";
    public static AMERICA_CUP = "AMERICA CUP";
    public static EUROCUP = "EURO CUP";
    public static WORLD_CUP = "WORLD CUP";

    public static NUM_EASY_ENDLESS = 40;
    public static NUM_MEDIUM_ENDLESS = 29;
    public static NUM_HARD_ENDLESS = 30;

    public static readonly TOURNAMENTS = [GameConstants.OCEANIA_CUP, GameConstants.AFRICA_CUP, GameConstants.ASIAN_CUP, GameConstants.GOLD_CUP, GameConstants.AMERICA_CUP, GameConstants.EUROCUP, GameConstants.WORLD_CUP];
    public static readonly MATCHES_IN_TORUNAMENT = 10;
    
    public static readonly BALL = "ball";
    public static readonly TUTORIAL_TARGET = "tutorial target";
    public static readonly PEG = "peg";
    public static readonly ELECTRIC_PLANK = "electric plank";
    public static readonly ELECTRIC_PEG = "electric peg";
    public static readonly MOVING_PLATFORM = "moving platform";
    public static readonly GOALKEEPER = "goalkeeper";
    public static readonly STARS_EMITTER = "coins emitter";
    public static readonly COIN = "coin";
    public static readonly COINS_FRENZY = "coins frenzy";
    public static readonly EXTRA_BALL = "extra ball";
    public static readonly BUMPER = "bumper";
    public static readonly FORWARD = "forward";
    public static readonly GOAL = "goal";
    public static readonly RIVAL_FORWARD = "rival forward";
    public static readonly WALL_GATE = "wall gate";

    public static readonly BALLS_COLLISION_CATEGORY = 2;
    public static readonly PEGS_COLLISION_CATEGORY = 4;

    public static readonly ABOVE = "above";
    public static readonly BELOW = "below";
    public static readonly LEFT = "left";
    public static readonly RIGHT = "right";

    public static readonly SAVED_GAME_DATA_KEY = "flappy-footchinko-endless-bounce";
}
