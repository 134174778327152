import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { FieldManager } from "./FieldManager";
import { GameManager } from "../../GameManager";
import { StageContainer } from "./StageContainer";
import { AudioManager } from "../../AudioManager";

export class KickOffForward extends Phaser.GameObjects.Container {

    private static KICKOFF_ANGLE = 35;

    public reachedPosition: boolean;

    private forward: Phaser.GameObjects.Image;
    private f: number;
    private justShot: boolean;
    private hasballAttached: boolean;

    private idleTextureFrame: string;
    private shootingTextureFrame: string;
    private withBallTextureFrame: string;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        this.f = 0;
        this.justShot = false;
        this.hasballAttached = true;
        this.reachedPosition = true;

        this.x = GameConstants.FIELD_WIDTH / 2;
        this.angle = KickOffForward.KICKOFF_ANGLE;

        const playerID = 31;

        this.idleTextureFrame = "delantero_Inicial_" + playerID + "_01";
        this.shootingTextureFrame = "delantero_Inicial_" + playerID + "_02";
        this.withBallTextureFrame = "delantero_Inicial_" + playerID + "_01";

        this.forward = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", this.idleTextureFrame);
        this.forward.setOrigin(.5);
        this.add(this.forward);

        // HAY Q HACER ESTO PQ EL METODO UPDATE NO SE UTILIZA DE MANERA AUTOMATICA
        this.scene.sys.updateList.add(this);
    }

    public preUpdate(): void {

        if (GameVars.gameOver || GameVars.placingExtraForwards) {
            return;
        }

        // mirar si se sale de la camara
        if (StageContainer.currentInstance && this.hasballAttached) {
            
            const cameraLimits = GameManager.getCameraLimits();

            if (this.y > cameraLimits.bottomY + 75) {
                    
                FieldManager.gameOver(GameConstants.GAME_OVER_KICKOFF_FORWARD_OUT_OF_SCREEN);
            }

            if (!this.reachedPosition && this.y * StageContainer.currentInstance.scaleY > cameraLimits.bottomY - 315) {
                
                this.reachedPosition = true;
                FieldManager.onKickOffForwardReachedPosition();
            }
        }

        if (this.justShot) {

            this.f ++;

            if (this.f === 8) {

                this.justShot = false;
                this.removeFromStage();
            }
        } 
    }

    public init(y: number): void {

        this.y = y;
        this.forward.setFrame(this.withBallTextureFrame);
        this.alpha = 1;
        this.visible = true;
        this.f = 0;
        this.justShot = false;
        this.hasballAttached = true;
        this.reachedPosition = false;
    }

    public activate(): void {

        this.forward.setFrame(this.withBallTextureFrame);
    }

    public deactivate(): void {

        this.forward.setFrame(this.idleTextureFrame);
    }

    public shoot(): void {

        this.justShot = true;
        this.hasballAttached = false;

        this.forward.setFrame(this.shootingTextureFrame);

        AudioManager.playSound("chuta_chutador");
    }

    private removeFromStage(): void {

        this.forward.setFrame(this.idleTextureFrame);

        this.scene.tweens.add({
            targets: this,
            alpha: 0,
            y: this.y + 100,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 650,
            yoyo: false,
            repeat: 0,
            onComplete: function(): void {
                this.visible = false;
            },
            onCompleteScope: this
        });
    }
}
