import "phaser";
// import SpineWebGLPlugin from "../node_modules/phaser/plugins/spine/dist/SpineWebGLPlugin";

import { Game } from "./Game";
import { GameConstants } from "./GameConstants";
import { BootScene } from "./scenes/BootScene";
import { PreloadScene } from "./scenes/PreloadScene";
import { SplashScene } from "./scenes/splash/SplashScene";
import { DevelopmentLevelSelectionScene } from "./scenes/development-level-selection/DevelopmentLevelSelectionScene";
import { FieldScene } from "./scenes/field/FieldScene";
import { GameVars } from "./GameVars";
import { GameManager } from "./GameManager";

let game: Game;

window.onload = () => {

    const gameConfig = {

        version: GameConstants.VERSION,
        type: Phaser.AUTO,
        backgroundColor: 0X000000,
        scale: {
            mode: Phaser.Scale.FIT,
            autoCenter: Phaser.Scale.CENTER_BOTH,
            parent: "content",
            width: GameConstants.GAME_WIDTH,
            height: GameConstants.GAME_HEIGHT
        },
        plugins: {
            scene: [
                {
                    key: "SpineWebGLPlugin",
                    plugin: SpineWebGLPlugin,
                    mapping: "spine"
                }
            ]
        },
        physics: {
            default: "matter",
            matter: {
                debug: GameConstants.SHOW_PHYSICS_BODIES,
                gravity: {
                    y: 1.5
                },
                enableSleeping: false
            }
        },
        scene:  [
                    BootScene, 
                    PreloadScene, 
                    SplashScene, 
                    DevelopmentLevelSelectionScene, 
                    FieldScene
                ]
    };

    game = new Game(gameConfig);

    window.focus();
    resize();

    window.addEventListener("resize", resize, false);
    window.addEventListener("orientationchange", checkOriention, false);
};

function resize(): void {

    const canvas = document.querySelector("canvas");
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const windowRatio = windowWidth / windowHeight;

    const gameWidth: any = game.config.width;
    const gameHeight: any = game.config.height;

    const gameRatio = gameWidth / gameHeight;
    
    if (windowRatio < gameRatio) {
        canvas.style.width = windowWidth + "px";
        canvas.style.height = (windowWidth / gameRatio) + "px";
    } else {
        canvas.style.width = (windowHeight * gameRatio) + "px";
        canvas.style.height = windowHeight + "px";
    }
}

function checkOriention(): void {

    const w = window.innerWidth;
    const h = window.innerHeight;

    if (h < w) {

        if (!GameVars.intitialised) {
            
            setTimeout(function() { 
                GameManager.init(); 
            }, 300);
        }

        document.getElementById("orientation").style.display = "none";
        document.getElementById("content").style.display = "block";
    } else {
        document.getElementById("orientation").style.display = "block";
        document.getElementById("content").style.display = "none";
    }
}
