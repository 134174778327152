import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";

export class DistanceMarker extends Phaser.GameObjects.Container {

    public static BADGE_PX = 35;

    private distanceLabel: Phaser.GameObjects.Text;
    private distanceBadge: Phaser.GameObjects.Image;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        this.y = - GameVars.dMarker * GameConstants.PIXELS_TO_METERS_FACTOR - 25;

        const recordLine = new Phaser.GameObjects.Image(this.scene, GameConstants.FIELD_WIDTH / 2, 0, "texture_atlas_1", "record_line");
        this.add(recordLine);

        this.distanceBadge = new Phaser.GameObjects.Image(this.scene, DistanceMarker.BADGE_PX, 0, "texture_atlas_1", "distance_badge");
        this.distanceBadge.alpha = .6;
        this.add(this.distanceBadge);

        this.scene.tweens.add({
            targets: this.distanceBadge,
            angle: 360,
            ease: Phaser.Math.Easing.Linear.Linear,
            duration: 4000,
            repeat: -1
        });

        this.distanceLabel = new Phaser.GameObjects.Text(this.scene, DistanceMarker.BADGE_PX, this.distanceBadge.y - 2, GameVars.dMarker + "m", {fontFamily: "OswaldDemibold", fontSize: "25px", color: "#FFFFFF"});
        this.distanceLabel.setStroke("#2c3031", 7);
        this.distanceLabel.setOrigin(.5);
        this.add(this.distanceLabel);
    }

    public onMarkerCrossed(): void {

        this.distanceBadge.visible = false;
        this.distanceLabel.visible = false;

        this.scene.tweens.add({
            targets: this,
            alpha: 0,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 350,
            delay: 300,
            onComplete: function(): void {
                this.rePosition();
            },
            onCompleteScope: this
        });
    }

    private rePosition(): void {

        this.distanceBadge.visible = true;
        this.distanceLabel.visible = true;

        this.alpha = 1;
        this.y = - GameVars.dMarker * GameConstants.PIXELS_TO_METERS_FACTOR - 25;
        this.distanceLabel.text = GameVars.dMarker + "m";
    }
}
