import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { Button } from "../../utils/Utils";
import { GameManager } from "../../GameManager";
import { AudioManager } from "../../AudioManager";

export class DevelopmentMenu extends Phaser.GameObjects.Container {

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.x = 410;
        this.y = GameConstants.GAME_HEIGHT * .5 + 100 * GameVars.scaleY;

        this.scaleY = GameVars.scaleY;

        const menuBackground = new Phaser.GameObjects.Graphics(this.scene);
        menuBackground.fillStyle(0xFFFFFF);
        menuBackground.fillRect(0, 0, 182, 95);
        this.add(menuBackground);

        const developmentLabel = new Phaser.GameObjects.Text(this.scene, 91, 10, "development menu", {fontFamily: "Arial", fontSize: "14px", color: "#FA0000"});
        developmentLabel.setOrigin(.5);
        this.add(developmentLabel);

        const tutorialLabel = new Phaser.GameObjects.Text(this.scene, 40, 82, "tutorial", {fontFamily: "Arial", fontSize: "14px", color: "#000000"});
        tutorialLabel.setOrigin(.5);
        this.add(tutorialLabel);

        const levelEditionLabel = new Phaser.GameObjects.Text(this.scene, 130, 82, "level edition", {fontFamily: "Arial", fontSize: "14px", color: "#000000"});
        levelEditionLabel.setOrigin(.5);
        this.add(levelEditionLabel);

        const levelEditionButton = new Button(this.scene, 135, 48, "texture_atlas_1", "btn_play_off", "btn_play_on", true);
        levelEditionButton.setScale(.75);
        levelEditionButton.onDown(this.onLevelEdition, this);
        this.add(levelEditionButton);
    }

    private onLevelEdition(): void {
        
        GameManager.enterDevelopmentLevelSelectionScene();

        AudioManager.playSound("btn_click");
    }
}
