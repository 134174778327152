import { GameConstants } from "../../GameConstants";

 export class ExplodedBall extends Phaser.GameObjects.Container {

    private ball: Phaser.GameObjects.Sprite;
    private f: number;
    private a: number;

    constructor(scene: Phaser.Scene, x: number, y: number) {
        
        super(scene);

        this.x = x;
        this.y = y;
        this.f = 0;
        this.a = 0;

        this.ball = new Phaser.GameObjects.Sprite(this.scene, 0, 0, "texture_atlas_1", "balon_electrocutado0001");

        this.scene.anims.create({ 
            key: "ball_explosion", 
            frames: this.scene.anims.generateFrameNames("texture_atlas_1", { prefix: "balon_electrocutado", start: 1, end: 4, zeroPad: 4}), 
            frameRate: 20,
            repeat: -1
        });

        this.ball.play("ball_explosion");
        this.scene.add.existing(this.ball);
        this.add(this.ball);

        // HAY Q HACER ESTO PQ EL METODO UPDATE NO SE UTILIZA DE MANERA AUTOMATICA
        this.scene.sys.updateList.add(this);
    }

    public preUpdate(): void {

        this.f ++;

        if (this.f === 5) {
            this.emmit();
        }

        if (this.f === 15) {
            this.emmit();
        }

        if (this.f > 25) {

            this.ball.y += 6;
            this.a += .05;
            this.ball.x = 15 * Math.sin(this.a);

            // LA HACEMOS TEMBLAR
            const dx = .025 - .05 * Math.random();
            const dy = .025 - .05 * Math.random();

            this.ball.setOrigin(.5 + dx, .5 + dy);

            if (Math.random() > .725) {

                this.emmit();
            }

            // si se sale de la pantalla destruirlo y dar tiempo a que desaparezca el humo
            // la posición en Y no es del todo exacta
            if (this.ball.y > GameConstants.GAME_HEIGHT + 350) {
                this.destroy();
            }
        }
    }

    private emmit(): void {

        const smokeParticle = new Phaser.GameObjects.Image(this.scene, this.ball.x, this.ball.y - 10, "texture_atlas_1", "smoke_particle");
        smokeParticle.angle = Math.random() * 360;
        smokeParticle.setScale(1 + .25 * Math.random());
        this.addAt(smokeParticle, 0);

        this.scene.tweens.add({
            targets: smokeParticle,
            alpha: 0,
            y: this.ball.y - 60,
            scaleX: smokeParticle.scaleX * 1.125,
            scaleY: smokeParticle.scaleX * 1.125,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 1000,
            yoyo: false,
            repeat: 0,
            onComplete: function(): void {
                smokeParticle.destroy();
            },
            onCompleteScope: this
        });
    }
}
