import { Wave } from "./Wave";
import { Ball } from "../physics/Ball";
import { KickOffForward } from "./KickOffForward";
import { BackgroundContainer } from "./BackgroundContainer";
import { GameVars } from "../../GameVars";
import { FieldManager } from "./FieldManager";
import { GameConstants } from "../../GameConstants";
import { Peg } from "../physics/Peg";
import { StarsEmitter } from "../physics/StarsEmitter";
import { ExtraBall } from "../physics/ExtraBall";
import { MovingPlatform } from "../physics/MovingPlatform";
import { Forward } from "../physics/Forward";
import { RivalForward } from "../physics/RivalForward";
import { Bumper } from "../physics/Bumper";
import { Goalkeeper } from "../physics/Goalkeeper";
import { ExplodedBall } from "./ExplodedBall";
import { ElectricPeg } from "../physics/ElectricPeg";
import { WallGate } from "../physics/WallGate";
import { GameManager } from "../../GameManager";
import { AudioManager } from "../../AudioManager";
import { DistanceMarker } from "./DistanceMarker";
import { StarsEffect } from "./StarsEffect";

export class StageContainer extends Phaser.GameObjects.Container {

    public static hasLevelGates = false;

    public static DELTA_Y_WAVE = 650;
    public static STAGE_FULL_SCREEN = "full screen stage";
    public static STAGE_WITH_VERTICAL_STRIPES = "stage with vertical stripes";

    public static currentInstance: StageContainer;

    public currentWave: Wave;
    public previousWave: Wave;
    
    private balls: Ball[];
    private kickOffForward: KickOffForward;
    private items: any [];
    private timeForwardCaughtBall: number;
    private currentDistanceMarker: DistanceMarker;
    
    constructor(scene: Phaser.Scene) {
        
        super(scene);

        StageContainer.currentInstance = this;

        this.items = [];
        this.balls = [];
        this.timeForwardCaughtBall = 0;
        
        this.setScaleAndPosition();

        const backgroundContainer = new BackgroundContainer(this.scene);
        this.add(backgroundContainer);

        this.currentWave = new Wave(this.scene, -350, this);
        this.previousWave = null;

        StageContainer.hasLevelGates = this.currentWave.wallGates.length > 0;
        
        this.kickOffForward = new KickOffForward(this.scene);
        this.kickOffForward.activate();
        this.add(this.kickOffForward);

        const ball = new Ball(this.scene);
        ball.x = this.kickOffForward.x + 34 * Math.sin(this.kickOffForward.rotation);
        ball.y = this.kickOffForward.y - 34 * Math.cos(this.kickOffForward.rotation);
        ball.rotation = this.kickOffForward.rotation;
        ball.initialPy = ball.y;
        this.add(ball);

        this.balls.push(ball);
        
        this.scene.matter.world.on("collisionstart", this.onCollisionStart, this);

        if (GameVars.screenshotMode) {

            const waveHeight = Math.abs(this.currentWave.waveHeight);

            if (waveHeight <= 1000) {
                this.setScale(.65);
            } else if (waveHeight <= 1500) {
                this.setScale(.49);
            } else if (waveHeight <= 2000) {
                this.setScale(.36);
            } else if (waveHeight <= 2500) {
                this.setScale(.3);
            } else {
                this.setScale(.25);
            }
            
            this.y = 110;
        }

        this.currentDistanceMarker = new DistanceMarker(this.scene);
        this.add(this.currentDistanceMarker);

        // HAY Q HACER ESTO PQ EL METODO UPDATE NO SE UTILIZA DE MANERA AUTOMATICA
        this.scene.sys.updateList.add(this);
    }

    public preUpdate(): void {

        const cameraLimits = GameManager.getCameraLimits();
        
        if (cameraLimits.topY < this.currentWave.waveHeight * this.scaleY) {
            FieldManager.nextEndlessWave();
        }
    }

    public onContinue(): void {

        const cameraLimits = GameManager.getCameraLimits();
        const kickOffForwardY = cameraLimits.bottomY - 150;

        this.kickOffForward.init(kickOffForwardY);
        this.kickOffForward.activate();

        const ball = new Ball(this.scene);
        ball.x = this.kickOffForward.x + 34 * Math.sin(this.kickOffForward.rotation);
        ball.y = this.kickOffForward.y - 34 * Math.cos(this.kickOffForward.rotation);
        ball.rotation = this.kickOffForward.rotation;
        this.add(ball);

        this.balls.push(ball);

        if (this.previousWave) {
            this.previousWave.onContinue(kickOffForwardY);
        }

        this.currentWave.onContinue(kickOffForwardY);
    }

    public onDown(): void {

        if (this.balls[0].kickOff) {
            FieldManager.kickOff();
        } else {

            for (let i = 0; i < this.balls.length; i++) {

                const ball = this.balls[i];

                if (ball.forward) {
                    if (this.scene.game.getTime() - this.timeForwardCaughtBall > 250) {
                        ball.forward.shoot();

                        FieldManager.onForwardShot();
                    }
                } else if (ball.bumper === null) {
                    this.balls[i].applyImpulse();
                }
            }
        }
    }

    public addEndlessWave(): void {

        this.previousWave = this.currentWave;
        this.currentWave = new Wave(this.scene, this.currentWave.waveHeight, this);

        StageContainer.hasLevelGates = this.currentWave.wallGates.length > 0;

        this.bringToTop(this.currentDistanceMarker);
    }

    public kickOff(): void {

        if (this.kickOffForward.reachedPosition)  {
        
            this.kickOffForward.shoot();
            this.balls[0].applyInitialImpulse();
        }
    }

    public addItem(item: any, addAtBottom?: boolean, isDebugItem?: boolean): void {

        addAtBottom = addAtBottom || false;
        isDebugItem = isDebugItem || false;

        if (addAtBottom) {
            this.addAt(item, 1);
        } else {
            this.add(item);
        }
        
        if (!isDebugItem) {
            this.items.push(item);
        }
    }

    public removeItem(item: any): void {

        const i = this.items.indexOf(item);
        this.items.splice(i, 1);

        if (item.sensor) {
            this.scene.matter.world.remove(item.sensor, true);
        }

        if (item.name === GameConstants.WALL_GATE) {

            const wallgate = <WallGate> item;
            wallgate.wave.removeWallGate(wallgate);
        }

        // quitarlo del array de la wave
        item.wave.removeItem(item);
        item.destroy();
    }

    public onCollisionStart(pairs: any, bodyA: any, bodyB: any): void {

        if (GameVars.gameOver) {
            return;
        }

        const collidingItems = GameVars.getCollidingItems(bodyA, bodyB);
        const ball = collidingItems.ball;
        const body = collidingItems.body;

        // DETECTAR AQUI SI NINGUNO DE LOS BODIES ES LA PELOTA
        if (!ball || !body || !body.gameObject)  {
            return;
        }

        // EL PEG NORMAL
        if (body.gameObject.name === GameConstants.PEG) {
            
            const peg = <Peg> body.gameObject;
            peg.hit();
            ball.bounce(peg);
        }

        // EL EMISOR DE MONEDAS
        if (body.gameObject.name === GameConstants.STARS_EMITTER) {
            
            const coinEmitter = <StarsEmitter> body.gameObject;
            coinEmitter.hit();
            ball.bounce(coinEmitter);

            FieldManager.coinEmitterHit(coinEmitter);
        }

        // EXTRA BALL
        if (body.gameObject.name === GameConstants.EXTRA_BALL) {
            
            const extraBall = <ExtraBall> body.gameObject;

            if (!extraBall.disappearing) {

                extraBall.hit();

                StageContainer.currentInstance.addExtraBall(ball, extraBall);
            }
        }

        // LOS PEGS ELECTRICOS
        if (body.gameObject.name === GameConstants.ELECTRIC_PEG) {

            const electricPeg = <ElectricPeg> body.gameObject.parentContainer;

            if (electricPeg.caged) {
                
                ball.bounce(body.gameObject);
                electricPeg.openCage();

            } else {

                if (electricPeg.isSwitch) {
                    ball.bounce(body.gameObject);
                    FieldManager.switchElectroPegs();
                } else {
                    ball.explode(electricPeg.isBesidesGoal ? GameConstants.GAME_OVER_GOAL_ELECTRIC_PEG : GameConstants.GAME_OVER_ELECTRIC_PEG);
                }
            }
        }

        // las plataformas moviles
        if (body.gameObject.name === GameConstants.MOVING_PLATFORM) {
            
            const movingPlatform = <MovingPlatform> body.gameObject;
            movingPlatform.hit(ball);
            ball.bounceOnMovingPlatform(movingPlatform);
        }

        // LOS DELANTEROS
        if (body.name === GameConstants.FORWARD) {

            const forward = <Forward> body.parent.gameObject;

            if (forward.caged) {
                forward.openCage();
                ball.bounce(forward);
            } else if (forward.canBallBeAttached && !forward.hasBallAttached && !ball.forward) {
                this.timeForwardCaughtBall = this.scene.game.getTime();
                forward.attach(ball);
            }
        }

        // LOS DELANTEROS RIVALES
        if (body.name === GameConstants.RIVAL_FORWARD) {

            const rivalForward = <RivalForward> body.parent.gameObject;

            if (!rivalForward.hasBallAttached) {

                if (ball.forward) {
                    ball.forward.onBallStolen();
                }

                if (ball.bumper) {
                    ball.bumper.onBallStolen();
                }

                this.attachBallToRivalForward(ball, rivalForward);
            }
        }

        // LOS BUMPERS
        if (body.name === GameConstants.BUMPER) {

            const bumper = <Bumper> body.parent.gameObject;

            if (bumper.canBallBeAttached) {
                bumper.attach(ball);
            }
        }

        // EL PORTERO
        if (body.name === GameConstants.GOALKEEPER) {

            const goalkeeper = <Goalkeeper> body.parent.gameObject;

            if (!goalkeeper.hasBallAttached) {

                if (ball.forward) {
                    ball.forward.onBallStolen();
                }

                this.attachBallToGoalkeeper(ball, goalkeeper);
            }
        }
    }

    public attachBallToGoalkeeper(ball: Ball, goalkeeper: Goalkeeper): void {

        goalkeeper.attachBall();

        const i = this.balls.indexOf(ball);
        this.balls.splice(i, 1);

        ball.destroy();

        if (this.balls.length === 0) {
            FieldManager.gameOver(GameConstants.GAME_OVER_GOALKEEPER);
        }
    }

    public attachBallToRivalForward(ball: Ball, rivalForward: RivalForward): void {

        const i = this.balls.indexOf(ball);
        this.balls.splice(i, 1);
        ball.destroy();

        rivalForward.attachBall();

        if (this.balls.length === 0) {

            FieldManager.gameOver(GameConstants.GAME_OVER_STOLEN_BALL);
        }
    }

    public onBallExploded(ball: Ball, originator: string): void {

        const explodedBall = new ExplodedBall(this.scene, ball.x, ball.y);
        this.add(explodedBall);

        // la sacamos del array
        const i = this.balls.indexOf(ball);
        this.balls.splice(i, 1);

        ball.destroy();

        if (this.balls.length === 0 ) {
            FieldManager.gameOver(originator);
        }
    }

    public onBallReachedFieldBottom(ball: Ball): void {

        // la sacamos del array
        const i = this.balls.indexOf(ball);
        this.balls.splice(i, 1);

        ball.destroy();

        if (this.balls.length === 0) {
            FieldManager.gameOver(GameConstants.GAME_OVER_BALL_HIT_BOTTOM);
        }
    }

    public ballAttachedToPlayerOutOfTheScreen(ball: Ball): void {

        // la sacamos del array
        const i = this.balls.indexOf(ball);
        this.balls.splice(i, 1);

        ball.destroy();

        if (this.balls.length === 0 ) {
            FieldManager.gameOver(GameConstants.GAME_OVER_FORWARD_OR_BUMPER_WITH_ATTACHED_BALL_OUT_OF_SCREEN);
        }
    }

    public addExtraBall(ball: Ball, extraBall: ExtraBall): void {

        const newBall = new Ball(this.scene);
        newBall.x = ball.x;
        newBall.y = ball.y;
        this.add(newBall);

        newBall.applyInitialImpulse(true, ball.x > extraBall.x);

        this.balls.push(newBall);
    }

    public addStar(x: number, y: number): void {

        this.addCoinEffect(x, y - 20);

        const star = new Phaser.GameObjects.Sprite(this.scene, x, y - 40, "texture_atlas_1");
        this.scene.add.existing(star);
        star.play("star-spin");
        this.add(star);

        const angle = (235 + 90 * Math.random()) * Math.PI / 180;

        this.scene.tweens.add({
            targets: star,
            x: x + 95 * Math.cos(angle),
            y: y - 40 + 95 * Math.sin(angle),
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 350
        });

        this.scene.tweens.add({
            targets: star,
            alpha: 0,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 150,
            delay: 350,
            onComplete: function(): void {
                star.destroy();
            },
            onCompleteScope: this
        });
    }

    public onDistanceMarkerCrossed(): void {

        const starsEffect = new StarsEffect(this.scene, 10);
        this.add(starsEffect);

        this.currentDistanceMarker.onMarkerCrossed();

        AudioManager.playSound("golden_pig");
    }

    public addCoinEffect(x: number, y: number): void {

        const coinFX = new Phaser.GameObjects.Sprite(this.scene, x, y, "texture_atlas_1", "coin_fx_0001");
        this.scene.add.existing(coinFX);
        coinFX.setScale(1.5 + Math.random() * .5);
        coinFX.angle = Math.random() * 360;
        coinFX.play("coin-fx");
        coinFX.on("animationcomplete", function(): void {
            coinFX.destroy();
        }, this);
        this.add(coinFX);
    }

    public onElectricPegReleased(pegY: number): void {

        for (let i = 0; i < this.items.length; i++) {

            const item = this.items[i];

            if (item.name === GameConstants.ELECTRIC_PEG) {

                const electricPeg = <ElectricPeg> item;
                
                if (electricPeg.moves && electricPeg.y === pegY - Wave.DY) {

                    electricPeg.release();
                }
            }
        }
    }

    public switchElectroPegs(): void {

        for (let i = 0; i < this.items.length; i ++) {
            if (this.items[i].name === GameConstants.ELECTRIC_PEG) {
                const electricPeg = <ElectricPeg> this.items[i];
                electricPeg.switch();
            }
        }

        AudioManager.playSound("switch");
    }

    public getCameraLimits(): {topY: number, bottomY: number} {

        return {topY: this.scene.cameras.main.scrollY, bottomY: this.scene.cameras.main.scrollY + GameConstants.GAME_HEIGHT + 40};
    }

    public hasBallCollidedAgainstWallGate(ball: Ball): {gateTouched: boolean, exitSide: string, py: number} {

        // let gateTouched = false;
        let enterGate: WallGate = null;
        let py = 0;
        let exitSide = "";
        let i: number;

        let wallGates = this.currentWave.wallGates.slice(0);

        if (this.previousWave && this.previousWave.wallGates.length > 0) {
            wallGates = wallGates.concat(this.previousWave.wallGates);
        }

        if (wallGates.length > 0) {
    
            // mirar si ha colisionado contra una de las paredes
            let touchedSide: string = null;

            if (ball.x <= Ball.RADIUS) {
                touchedSide = GameConstants.LEFT;
            } else if (ball.x >= GameConstants.FIELD_WIDTH - Ball.RADIUS ) {
                touchedSide = GameConstants.RIGHT;
            }

            if (touchedSide) {

                let wallGate: WallGate;

                for (i = 0; i < wallGates.length; i ++) {   

                    wallGate = wallGates[i];

                    if (wallGate.side === touchedSide && ball.y > wallGate.y && ball.y < wallGate.y + wallGate.gateLength)  {
                       
                        enterGate = wallGate;
                        break;
                    }
                }

                if (enterGate) {
                  
                    // TODO: mirar si la que esta antes o despues estan dentro de la pantalla
                    let exitGate: WallGate = null;

                    if (i < wallGates.length - 1 && wallGates[i + 1].isGateInViewPort()) {
                        exitGate = wallGates[i + 1];
                    } else  if (i > 0 && wallGates[i - 1].isGateInViewPort()) {
                        exitGate = wallGates[i - 1];
                    }  

                    if (exitGate === null) {
                        exitGate = wallGates[i];
                    }

                    exitSide = exitGate.side;

                    py = exitGate.y - (wallGate.y - ball.y); // TODO: en el caso de que todos no tengal la misma longitud habría que hacer la dist proporcional

                    enterGate.ballEnteredGate(enterGate.id === exitGate.id);
                    exitGate.ballExitedGate(enterGate.id === exitGate.id);
                }
            }
        }

        return {gateTouched: enterGate !== null, exitSide: exitSide, py: py};
    }

    private setScaleAndPosition(): void {

        if (this.scene.game.device.os.desktop) {

            this.x = (GameConstants.GAME_WIDTH - GameConstants.FIELD_WIDTH) * .5;

        } else {

            if (GameVars.stageDisplayMode === StageContainer.STAGE_WITH_VERTICAL_STRIPES) {

                this.x = (GameConstants.GAME_WIDTH - GameConstants.FIELD_WIDTH / GameVars.scaleY) * .5;
                this.scaleX = 1 / GameVars.scaleY;
                
            } else {

                this.x = 0;
                this.scaleX = GameConstants.GAME_WIDTH / GameConstants.FIELD_WIDTH;

                if (this.scene.game.scale.displaySize.aspectRatio < .5) {
                    this.scaleY *= GameConstants.STAGE_CONTAINER_IPHONE_X_SCALE_FACTOR ;
                }
            }
        }
    }
}
