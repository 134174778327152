import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { StageContainer } from "../field/StageContainer";
import { GameManager } from "../../GameManager";
import { AudioManager } from "../../AudioManager";
import { Wave } from "../field/Wave";

export class RivalForward extends Phaser.GameObjects.Container implements WaveItem {

    public deactivated: boolean;
    public hasBallAttached: boolean;
    public sensor: any;
    public wave: Wave;

    private forward: Phaser.GameObjects.Image;
    private rightSide: boolean;
    private isMoving: boolean;
    private speedFactor: number;
    private f: number;
    private runFrame1: string;
    private runFrame2: string;
    private runWithBallFrame1: string;
    private runWithBallFrame2: string;

    constructor(scene: Phaser.Scene, wave: Wave, x: number, y: number, moves?: boolean) {
        
        super(scene);

        this.wave = wave;
        this.x = x;
        this.y = y;

        this.hasBallAttached = false;
        this.isMoving = false;
        this.speedFactor = 1;
        this.f = 0;

        if (x < GameConstants.GAME_WIDTH / 2) {
            this.rightSide  = false;
        } else {
            this.rightSide  = true;
        }

        const currentTeamID = 5;

        this.runFrame1 = "ladron_" + currentTeamID + "_01";
        this.runFrame2 = "ladron_" + currentTeamID + "_02";
        this.runWithBallFrame1 = "ladron_" + currentTeamID + "_03";
        this.runWithBallFrame2 = "ladron_" + currentTeamID + "_04";

        this.forward = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_2", this.runFrame1);
        this.scene.add.existing(this.forward); // para que la animacion funcione
        this.add(this.forward);

        this.sensor = this.scene.matter.add.circle(x, y, 30, {isSensor: true, isStatic: false}, 5);
        this.sensor.parent.gameObject = this;
        this.sensor.ignoreGravity = true;
        this.sensor.collisionFilter.category = GameConstants.PEGS_COLLISION_CATEGORY;
        this.sensor.collisionFilter.mask = GameConstants.BALLS_COLLISION_CATEGORY;
        this.sensor.name = GameConstants.RIVAL_FORWARD;

        // HAY Q HACER ESTO PQ EL METODO UPDATE NO SE UTILIZA DE MANERA AUTOMATICA
        this.scene.sys.updateList.add(this);
    }

    public preUpdate(time: number, delta: number): void {

        if (GameVars.placingExtraForwards || !GameVars.scrolling) {
            return;
        }

        const cameraLimits = GameManager.getCameraLimits();

        if (this.y > cameraLimits.bottomY) {
            this.scene.matter.world.remove(this.sensor, true);
            StageContainer.currentInstance.removeItem(this);
            return;
        }

        // los 15 son para que no se empiece a mover de golpe, se puede precalcular para no tener q hacer la resta extra                                                                                      
        if (!this.isMoving && this.y * StageContainer.currentInstance.scaleY > cameraLimits.topY - 50 * StageContainer.currentInstance.scaleY) {
            this.isMoving = true;
        }

        if (this.isMoving) {

            this.f ++;

            if (this.hasBallAttached) {

                if (this.f === 8) {
                    this.f = 0;
                    
                    if (this.forward.frame.name === this.runWithBallFrame1) {
                        this.forward.setFrame(this.runWithBallFrame2);
                        AudioManager.playSound("footstep_thief_1");
                    } else {
                        this.forward.setFrame(this.runWithBallFrame1);
                        AudioManager.playSound("footstep_thief_2");
                    }
                }

            } else {

                if (this.f === 8) {
                    this.f = 0;
                    if  (this.forward.frame.name === this.runFrame1) {
                        this.forward.setFrame(this.runFrame2);
                        AudioManager.playSound("footstep_thief_1");
                    } else {
                        this.forward.setFrame(this.runFrame1);
                        AudioManager.playSound("footstep_thief_2 ");
                    }
                }

                if (this.rightSide) {
                    this.x -= 2.5;
                } else {
                    this.x += 2.5;
                }

                this.sensor.position.x = this.x;
                this.sensor.position.y = this.y;
            }
            
            this.y += 5 * this.speedFactor;
        }
    }

    public deactivate(): void {
        
        if (!this.scene) {
            return;
        }
        
        this.deactivated = true;

        this.alpha = GameConstants.ALPHA_DEACTIVATED_ITEMS;

        this.sensor.collisionFilter.mask = 0;
    }

    public attachBall(): void {

        this.hasBallAttached = true;
        this.speedFactor = 2.5;

        const ballImg = new Phaser.GameObjects.Image(this.scene, 0, 42, "texture_atlas_1", "ball");
        this.add(ballImg);

        if (Math.random() > .9) {
            AudioManager.playSound("defense_steals_ball_rare");
        } else {
            AudioManager.playSound("defense_steals_ball");
        }
    }
}
