import { GameVars } from "../../GameVars";
import { StageContainer } from "./StageContainer";
import { GameConstants } from "../../GameConstants";
import { FieldScene } from "./FieldScene";
import { GameManager } from "../../GameManager";
import { StarsEmitter } from "../physics/StarsEmitter";
import { Ball } from "../physics/Ball";

export class FieldManager {

    public static waveFrameKey: string;

    private static endlessLevelsFrameKeys: string [] = [];

    public static init(): void {

        if (GameVars.gameData.currentWave !== 0 && !GameVars.screenshotMode) {
            GameVars.scrolling = true;
        }
       
        GameVars.gameOver = false;
        GameVars.stars = 0;
        
        GameVars.continued = false;
        GameVars.dMarker = GameConstants.DELTA_METERS_MARKER;

        // TODO: mientras estamos desarrollando
        GameVars.firstWave = true;

        GameVars.newRecord = false;

        // para hacer un mirroring de los pegs para dar mas variedad
        GameVars.mirrorPegs = Math.random() > .5;

        // para los logros
        GameVars.kicks = 0;
        GameVars.pigHits = 0;
        GameVars.metersBeforeContinue = 0;
        GameVars.starsBeforeContinue = 0;
        
        FieldManager.generateEndlessLevelsFrameKeys();
    
        FieldManager.readWaveData();
    }  

    public static readWaveData(): void {

        FieldManager.waveFrameKey = FieldManager.getEndlessModeFrameKey();

        const atlas = GameVars.currentScene.textures.get("texture_atlas_0");
        const frame = atlas.get(FieldManager.waveFrameKey);

        const cols = frame.width;
        const rows = frame.height;

        GameVars.waveRows = [];

        let canvasTexture: Phaser.Textures.CanvasTexture;

        if (GameVars.canvasTextures.hasOwnProperty(FieldManager.waveFrameKey)) {
            canvasTexture = GameVars.canvasTextures[FieldManager.waveFrameKey];
        } else {
            canvasTexture = GameVars.currentScene.textures.createCanvas(FieldManager.waveFrameKey, frame.cutWidth, frame.cutHeight);
            canvasTexture.drawFrame("texture_atlas_0", FieldManager.waveFrameKey, 0, 0);
            GameVars.canvasTextures[FieldManager.waveFrameKey] = canvasTexture;
        }

        for (let row = 0; row < rows; row ++ ) {

            GameVars.waveRows[row] = [];

            for (let col = 0; col < cols; col ++) {
                
                const pixel = canvasTexture.getPixel(col, row);
                
                const r = pixel.red; 
                const g = pixel.green;
                const b = pixel.blue; 

                if (r === 0xFF && g === 0xFF && b === 0xFF) {
                    GameVars.waveRows[row].push(1);                         // peg elécrico inmóvil
                } else if (r === 0xFF && g === 0x00 && b === 0x00) {
                    GameVars.waveRows[row].push(2);                         // el portero
                } else if (r === 0xFF && g === 0xFF && b === 0x00) {
                    GameVars.waveRows[row].push(3);                         // la moneda
                } else if (r === 0x00 && g === 0x00 && b === 0xFF) {
                    GameVars.waveRows[row].push(4);                         // el peg rojo
                } else if (r === 0x00 && g === 0xFF && b === 0xFF) {
                    GameVars.waveRows[row].push(5);                         // el peg con un toque
                } else if (r === 0x5F && g === 0x9E && b === 0xA0) {
                    GameVars.waveRows[row].push(6);                         // el peg solido
                } else if (r === 0x55 && g === 0x55 && b === 0x55) {       
                    GameVars.waveRows[row].push(7);                         // el peg electrico movil
                } else if (r === 0x00 && g === 0xFF && b === 0x00) {
                    GameVars.waveRows[row].push(8);                         // el delantero quieto
                } else if (r === 0xFF && g === 0x00 && b === 0xFF) {
                    GameVars.waveRows[row].push(9);                         // el delantero q se mueve horizontalmente
                } else if (r === 0xFF && g === 0x80 && b === 0x00) {
                    GameVars.waveRows[row].push(10);                        // el bumper 
                } else if (r === 0x19 && g === 0x19 && b === 0x70) {
                    GameVars.waveRows[row].push(11);
                } else if (r === 0xBD && g === 0xFC && b === 0xC9) {
                    GameVars.waveRows[row].push(12);                        // el delantero rival
                } else if (r === 0xBC && g === 0x8F && b === 0x8F) {
                    GameVars.waveRows[row].push(13);                        // el bumper q se mueve
                } else if (r === 0xFF && g === 0x41 && b === 0x72) {
                    GameVars.waveRows[row].push(14);                        // la plataforma que se mueve a la derecha
                } else if (r === 0x9A && g === 0xFF && b === 0x8D) {
                    GameVars.waveRows[row].push(15);                        // la plataforma que se mueve a la izquierda
                } else if (r === 0xF5 && g === 0xFF && b === 0xA3) {

                    GameVars.waveRows[row].push(3);                         // lo que antes era coins frenzy pasa a ser un cerdo normal
                        
                } else if (r === 0xCC && g === 0xCC && b === 0xCC) {
                    GameVars.waveRows[row].push(17);                        // la pelota extra
                } else if (r === 0x27 && g === 0xAE && b === 0xB9) {
                    GameVars.waveRows[row].push(18);                        // el switch de los pegs electricos
                } else if (r === 0x00 && g === 0x80 && b === 0x00) {
                    GameVars.waveRows[row].push(19);                        // el delantero enjaulado
                } else if (r === 0xFF && g === 0xA5 && b === 0x00) {
                    GameVars.waveRows[row].push(20);                        // el peg electrico enjaulado
                } else {
                    GameVars.waveRows[row].push(0);
                }
            }
        }

        if (GameVars.mirrorPegs) {
            // hacemos una simetria de cada linea
            for (let r = 0; r < GameVars.waveRows.length; r ++) {
                const row = GameVars.waveRows[r].slice(0);
                GameVars.waveRows[r].length = 0;
                for (let c = row.length - 1 ; c >= 0; c --) {
                    GameVars.waveRows[r].push(row[c]);
                }
            }
        } 
    }

    public static onTournamentIntroSeen(): void {

        if (GameVars.gameData.currentWave !== 0) {
            GameVars.scrolling = true;
        }

        FieldScene.currentInstance.removeTournamentIntroLayer();
    }

    public static onKickOffForwardReachedPosition(): void {

        FieldScene.currentInstance.onKickOffForwardReachedPosition();
    }

    public static kickOff(): void {

        GameVars.scrolling = true;
        
        StageContainer.currentInstance.kickOff();
    }

    public static onForwardShot(): void {

        GameVars.kicks ++;
        GameVars.gameData.kicks ++;
    }

    public static switchElectroPegs(): void {
       
        StageContainer.currentInstance.switchElectroPegs();

        FieldScene.currentInstance.onElectricPegsSwitched();
    }

    public static onDistanceMarkerCrossed(): void {

        GameVars.dMarker += GameConstants.DELTA_METERS_MARKER;
        GameVars.stars += GameConstants.STARS_ON_CROSSED_MARKER;
        GameVars.gameData.totalStars += GameConstants.STARS_ON_CROSSED_MARKER;

        FieldScene.currentInstance.onDistanceMarkerCrossed();
    }

    public static coinEmitterHit(starEmitter: StarsEmitter): void {

        let numStars = starEmitter.isUnicorn ? 2 : 1;

        GameVars.stars += numStars;
        GameVars.gameData.totalStars += numStars;

        GameManager.writeGameData();

        for (let i = 0; i < numStars; i ++) {
            StageContainer.currentInstance.addStar(starEmitter.x, starEmitter.y);
        }

        GameVars.pigHits ++;
        GameVars.gameData.pigHits ++;
       
        FieldScene.currentInstance.coinObtained();
    }

    public static nextEndlessWave(): void {

        FieldManager.readWaveData();

        StageContainer.currentInstance.addEndlessWave();
    }

    public static gameOver(gameOverCause: string): void {

        GameVars.gameOver = true;
        GameVars.scrolling = false;
        GameVars.gameOverCause = gameOverCause;

        FieldScene.currentInstance.onLevelOver();

        GameManager.gameOver();

        GameVars.simplio.SetScore(GameVars.simplioUserId, GameVars.stars).then(() => {
            console.warn("Score updated");
        });
    }

    public static onContinue(): void {

        GameVars.gameOver = false;
        GameVars.continued = true;

        GameVars.starsBeforeContinue = GameVars.stars;
        GameVars.metersBeforeContinue = Math.floor(Ball.currentInstance.maxY);
        GameVars.kicks = 0;
        GameVars.pigHits = 0;

        FieldScene.currentInstance.onContinue();
    }
    
    private static getEndlessModeFrameKey(): string {

        let frameKey: string;
        let suffix: string;
        let numLevels: number;
        let scrollSpeedFactor: number;

        if (GameVars.editingLevels) {

            if (GameVars.difficulty === GameConstants.ENDLESS_EASY) {
                suffix = "easy";
                numLevels = GameConstants.NUM_EASY_ENDLESS;
                scrollSpeedFactor = 1;
            } else if (GameVars.difficulty === GameConstants.ENDLESS_MEDIUM) {
                suffix = "medium";
                numLevels = GameConstants.NUM_MEDIUM_ENDLESS;
                scrollSpeedFactor = 1.7;
            } else {
                suffix = "hard";
                numLevels = GameConstants.NUM_HARD_ENDLESS;
                scrollSpeedFactor = 2;
            }
    
            if (GameVars.firstWave) {

                frameKey = "endless-" + suffix + "-wave-" + GameVars.gameData.currentWave;
                GameVars.firstWave = false;

                FieldScene.currentInstance.scrollSpeedFactor = scrollSpeedFactor;
    
            } else {
                const i = Math.floor(Math.random() * numLevels);
                frameKey = "endless-" + suffix + "-wave-" + i;
            }

        } else if (!GameVars.gameData.endlessModePlayed) {

            frameKey = "endless-easy-wave-0";

            GameVars.gameData.endlessModePlayed = true;
            GameManager.writeGameData();

        } else {

            if (FieldManager.endlessLevelsFrameKeys.length > 0) {
                frameKey = FieldManager.endlessLevelsFrameKeys.shift();
            } else {
                const i = Math.floor(Math.random() * GameConstants.NUM_HARD_ENDLESS);
                frameKey = "endless-hard-wave-" + i;
            }
        }

        return frameKey;
    }

    private static generateEndlessLevelsFrameKeys(): void {

        FieldManager.endlessLevelsFrameKeys.length = 0;

        let easyIndexes: number [] = [];
        let mediumIndexes: number [] = [];
        let hardIndexes: number [] = [];

        for (let i = 0; i < GameConstants.NUM_EASY_ENDLESS; i ++) {
            easyIndexes.push(i);
        }

        easyIndexes = Phaser.Utils.Array.Shuffle(easyIndexes);

        for (let i = 0; i < GameConstants.NUM_MEDIUM_ENDLESS; i ++) {
            mediumIndexes.push(i);
        }

        mediumIndexes = Phaser.Utils.Array.Shuffle(mediumIndexes);

        for (let i = 0; i < GameConstants.NUM_HARD_ENDLESS; i ++) {
            hardIndexes.push(i);
        }

        hardIndexes = Phaser.Utils.Array.Shuffle(hardIndexes);

        let index: number;
        let frameKey: string;

        // 3 easy
        for (let i = 0; i < 3; i ++) {
            index = easyIndexes.shift();
            frameKey = "endless-easy-wave-" + index;
            FieldManager.endlessLevelsFrameKeys.push(frameKey);
        }

        // 3 medium
        for (let i = 0; i < 4; i ++) {

            if (i === 1 || (Math.random() > .75 && i === 3)) {
                index = easyIndexes.shift();
                frameKey = "endless-easy-wave-" + index;
            } else {
                index = mediumIndexes.shift();
                frameKey = "endless-medium-wave-" + index;
            }

            FieldManager.endlessLevelsFrameKeys.push(frameKey);
        }

        // a partir de aqui todos dificiles con alguno medio
        while (hardIndexes.length > 0) {

            if (Math.random() > .7 && mediumIndexes.length > 0) {
                index = mediumIndexes.shift();
                frameKey = "endless-medium-wave-" + index;
            } else {
                index = hardIndexes.shift();
                frameKey = "endless-hard-wave-" + index;
            }

            FieldManager.endlessLevelsFrameKeys.push(frameKey);
        }
    }
}
