import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";

export class GameTitle extends Phaser.GameObjects.Container {

    private flappy: Phaser.GameObjects.Image;
    private foot: Phaser.GameObjects.Image;
    private chinko: Phaser.GameObjects.Image;
    private endlessBounce: Phaser.GameObjects.Image;

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.x = GameConstants.GAME_WIDTH / 2;
        this.y = GameConstants.GAME_HEIGHT / 2 - 200 * GameVars.scaleY;

        if (this.scene.game.scale.displaySize.aspectRatio < .5) {
            this.y = GameConstants.GAME_HEIGHT / 2 - 340 * GameVars.scaleY;
        }

        this.scaleY = GameVars.scaleY;

        this.flappy = new Phaser.GameObjects.Image(this.scene, 0, -20, "texture_atlas_2", "title_flappy");
        this.flappy.alpha = 0;
        this.add(this.flappy);

        this.foot = new Phaser.GameObjects.Image(this.scene, -170, 165, "texture_atlas_2", "title_foot");
        this.foot.originY = 1;
        this.foot.angle = -100;
        this.add(this.foot);

        this.chinko = new Phaser.GameObjects.Image(this.scene, 220, 100, "texture_atlas_2", "title_chinko");
        this.chinko.originY = 1;
        this.chinko.angle = 100;
        this.add(this.chinko);

        this.endlessBounce = new Phaser.GameObjects.Image(this.scene, 0, -15, "texture_atlas_2", "endless_bounce");
        this.endlessBounce.alpha = 0;
        this.add(this.endlessBounce);

        this.scene.time.delayedCall(350, this.animateTitle, [], this);
    }

    private animateTitle(): void {

        this.scene.tweens.add({
            targets: this.flappy,
            alpha: 1,
            y: 0,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 350
        });

        this.scene.tweens.add({
            targets: this.foot,
            x: -60,
            angle: 0,
            ease: Phaser.Math.Easing.Bounce.Out,
            duration: 750,
            delay: 600
        });

        this.scene.tweens.add({
            targets: this.foot,
            angle: -5,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 150,
            delay: 2700, 
            loop: 1e3,
            loopDelay: 2000,
            yoyo: true
        });

        this.scene.tweens.add({
            targets: this.chinko,
            x: 105,
            angle: 0,
            ease: Phaser.Math.Easing.Bounce.Out,
            duration: 600,
            delay: 1200
        });

        this.scene.tweens.add({
            targets: this.chinko,
            angle: 5,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 150,
            delay: 2800, 
            loop: 1e3,
            loopDelay: 2000,
            yoyo: true
        });

        this.scene.tweens.add({
            targets: this.endlessBounce,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 250,
            delay: 1800
        });

        this.scene.tweens.add({
            targets: this.endlessBounce,
            y: 35,
            ease: Phaser.Math.Easing.Bounce.Out,
            duration: 650,
            delay: 1800
        });
    }
}
