import {Howl} from "howler";
import { GameManager } from "../GameManager";
import { GameConstants } from "../GameConstants";
import { GameVars } from "../GameVars";
import { AudioManager } from "../AudioManager";

export class PreloadScene extends Phaser.Scene {

    public static currentInstance: PreloadScene;

    private progressBar: Phaser.GameObjects.Graphics;
    
    constructor() {

        super("PreloadScene");
    }

    public preload(): void {

        this.composeScene();

        this.loadAssets();
    }

    public create(): void {
    
        PreloadScene.currentInstance = this;
        GameManager.setCurrentScene(this);

        this.loadHowl();
    }

    private updateLoadedPercentage(percentageLoaded: number): void {
        //
    }

    private composeScene(): void {

        this.add.text(-100, 100, "abcdef", {fontFamily: "OswaldDemibold", fontSize: "26px", color: "#FFFF00"});

        const background = this.add.graphics();
        background.fillStyle(0x061F2E);
        background.fillRect(0, 0, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT);

        const scaledItemsContainer = this.add.container(GameConstants.GAME_WIDTH / 2, GameConstants.GAME_HEIGHT / 2);
        scaledItemsContainer.scaleY = GameVars.scaleY;

        const loadIcon = new Phaser.GameObjects.Image(this, 0, 0, "load_icon");
        scaledItemsContainer.add(loadIcon);

        this.tweens.add({
            targets: loadIcon,
            angle: 360,
            ease: Phaser.Math.Easing.Linear,
            duration: 3500,
            repeat: -1
        });

    }

    private loadAssets(): void {

        this.load.image("home-background", "assets/splash_background.jpg");

        const date =  Date.now();
        
        this.load.atlas("texture_atlas_0", "assets/texture_atlas_0.png?v=" + date, "assets/texture_atlas_0.json?v=" + date);
        this.load.atlas("texture_atlas_1", "assets/texture_atlas_1.png", "assets/texture_atlas_1.json");
        this.load.atlas("texture_atlas_2", "assets/texture_atlas_2.png", "assets/texture_atlas_2.json");

        (this.load as any).spine("characters", "assets/spine/export_letras.json", "assets/spine/export_letras.atlas");
   
        // audio
        this.load.json("audiosprite", "assets/audio/audiosprite.json");

        this.load.on("progress", this.updateLoadedPercentage, this);
    }

    private loadHowl(): void {

        let json = this.cache.json.get("audiosprite");
        json = JSON.parse(JSON.stringify(json).replace("urls", "src"));
        json = JSON.parse(JSON.stringify(json).replace("../", ""));
        json = JSON.parse(JSON.stringify(json).replace("../", ""));

        AudioManager.howl = new Howl(json);

        AudioManager.howl.on("load", function(): void {

         
            GameManager.onGameAssetsLoaded();
        });
    }
}
