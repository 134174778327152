import { StageContainer } from "./StageContainer";
import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { DistanceMarker } from "./DistanceMarker";

export class BackgroundContainer extends Phaser.GameObjects.Container {

    public static currentInstance: BackgroundContainer;

    private upperSegment: Phaser.GameObjects.Container;
    private lowerSegment: Phaser.GameObjects.Container;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        BackgroundContainer.currentInstance = this;

        this.lowerSegment = new Phaser.GameObjects.Container(this.scene);
        this.add(this.lowerSegment);

        for (let i = 0; i < 4; i ++) {
            for (let j = 0; j < 2; j++) { 
                const grassTile = new Phaser.GameObjects.Image(this.scene, j * 240, i * 240, "texture_atlas_1", "grass_tile_00");
                grassTile.setOrigin(0, 0);
                grassTile.setScale(1.01);
                this.lowerSegment.add(grassTile);
            }
        }

        this.upperSegment = new Phaser.GameObjects.Container(this.scene);
        this.upperSegment.y = -4 * 240;
        this.add(this.upperSegment);

        for (let i = 0; i < 4; i ++) {
            for (let j = 0; j < 2; j++) {
                const grassTile = new Phaser.GameObjects.Image(this.scene, j * 240, i * 240, "texture_atlas_1", "grass_tile_00");
                grassTile.setOrigin(0, 0);
                grassTile.setScale(1.01);
                this.upperSegment.add(grassTile);
            }
        }
 
        if (GameVars.stageDisplayMode === StageContainer.STAGE_WITH_VERTICAL_STRIPES) {
 
            const leftBorder = new Phaser.GameObjects.Image(this.scene, -.5 * (GameConstants.GAME_WIDTH - GameConstants.FIELD_WIDTH), 0, "texture_atlas_1", "field_border");
            leftBorder.setScrollFactor(0);
            leftBorder.setOrigin(1, 0);
            leftBorder.scaleX = -1;
            this.add(leftBorder);

            const rightBorder = new Phaser.GameObjects.Image(this.scene, GameConstants.FIELD_WIDTH, 0, "texture_atlas_1", "field_border");
            rightBorder.setScrollFactor(0);
            rightBorder.setOrigin(0, 0);
            this.add(rightBorder);
        }

        if (GameVars.screenshotMode) {
            this.visible = false;
        }
        
        // HAY Q HACER ESTO PQ EL METODO UPDATE NO SE UTILIZA DE MANERA AUTOMATICA
        this.scene.sys.updateList.add(this);
    }

    public preUpdate(): void {

        if (!GameVars.scrolling) {
            return;
        }

        if (this.upperSegment.y * StageContainer.currentInstance.scaleY > this.scene.cameras.main.scrollY ) {

            const tmpSegment = this.upperSegment;
            this.upperSegment = this.lowerSegment;
            this.lowerSegment = tmpSegment;

            this.upperSegment.y = this.lowerSegment.y - 4 * 240;
        }        
    }

    public reset(): void {

        // HAY Q HACER ESTO PQ EL METODO UPDATE NO SE UTILIZA DE MANERA AUTOMATICA
        this.scene.sys.updateList.add(this);

        this.lowerSegment.y = 0;
        this.upperSegment.y = -4 * 240;
    }

    public addSpectators(): void {

        const spectatorsContainer = new Phaser.GameObjects.Container(this.scene);
        spectatorsContainer.alpha = 0;
        this.add(spectatorsContainer);

        this.scene.tweens.add({
            targets: spectatorsContainer,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 450
        });

        let leftSpectators = new Phaser.GameObjects.Image(this.scene, -.5 * (GameConstants.GAME_WIDTH - GameConstants.FIELD_WIDTH), -384, "texture_atlas_1", "tile_spectators");
        leftSpectators.setScrollFactor(0);
        leftSpectators.scaleX = -1;
        spectatorsContainer.add(leftSpectators);

        leftSpectators = new Phaser.GameObjects.Image(this.scene, -.5 * (GameConstants.GAME_WIDTH - GameConstants.FIELD_WIDTH), 0, "texture_atlas_1", "tile_spectators");
        leftSpectators.setScrollFactor(0);
        leftSpectators.scaleX = -1;
        spectatorsContainer.add(leftSpectators);

        leftSpectators = new Phaser.GameObjects.Image(this.scene, -.5 * (GameConstants.GAME_WIDTH - GameConstants.FIELD_WIDTH), 384, "texture_atlas_1", "tile_spectators");
        leftSpectators.setScrollFactor(0);
        leftSpectators.scaleX = -1;
        spectatorsContainer.add(leftSpectators);

        leftSpectators = new Phaser.GameObjects.Image(this.scene, -.5 * (GameConstants.GAME_WIDTH - GameConstants.FIELD_WIDTH), 768, "texture_atlas_1", "tile_spectators");
        leftSpectators.setScrollFactor(0);
        leftSpectators.scaleX = -1;
        spectatorsContainer.add(leftSpectators);

        leftSpectators = new Phaser.GameObjects.Image(this.scene, -.5 * (GameConstants.GAME_WIDTH - GameConstants.FIELD_WIDTH), 1152, "texture_atlas_1", "tile_spectators");
        leftSpectators.setScrollFactor(0);
        leftSpectators.scaleX = -1;
        spectatorsContainer.add(leftSpectators);

        let rightSpectators = new Phaser.GameObjects.Image(this.scene, GameConstants.FIELD_WIDTH, -384, "texture_atlas_1", "tile_spectators");
        rightSpectators.setScrollFactor(0);
        rightSpectators.setOrigin(0, 0);
        spectatorsContainer.add(rightSpectators);

        rightSpectators = new Phaser.GameObjects.Image(this.scene, GameConstants.FIELD_WIDTH, 0, "texture_atlas_1", "tile_spectators");
        rightSpectators.setScrollFactor(0);
        rightSpectators.setOrigin(0, 0);
        spectatorsContainer.add(rightSpectators);

        rightSpectators = new Phaser.GameObjects.Image(this.scene, GameConstants.FIELD_WIDTH, 384, "texture_atlas_1", "tile_spectators_2");
        rightSpectators.setScrollFactor(0);
        rightSpectators.setOrigin(0, 0);
        spectatorsContainer.add(rightSpectators);

        rightSpectators = new Phaser.GameObjects.Image(this.scene, GameConstants.FIELD_WIDTH, 768, "texture_atlas_1", "tile_spectators");
        rightSpectators.setScrollFactor(0);
        rightSpectators.setOrigin(0, 0);
        spectatorsContainer.add(rightSpectators);
    }
}
