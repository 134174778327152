import { GameVars } from "..//GameVars";
import { GameManager } from "..//GameManager";
import { Button } from "..//utils/Utils";
import { AudioManager } from "..//AudioManager";
import { GameConstants } from "../GameConstants";

export class EndlessModePlayButtonContainer extends Phaser.GameObjects.Container {

    private leavingScene: boolean;

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.x = GameConstants.GAME_WIDTH / 2;
        this.y = GameConstants.GAME_HEIGHT - 110 * GameVars.scaleY;
        this.scaleY = GameVars.scaleY;

        this.leavingScene = false;

        if (this.scene.game.scale.displaySize.aspectRatio < .75) {

            if (this.scene.game.scale.displaySize.aspectRatio < .5) {
                this.y += 40 * GameVars.scaleY;
            } else {
                this.y += 15 * GameVars.scaleY;
            }
        }

        const badge = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "tournament_star");
        this.add(badge); 

        const playButton = new Button(this.scene, 10, -5, "texture_atlas_1", "btn_play_off", "btn_play_on", true);
        playButton.onDown(this.onClickPlay, this);
        playButton.setScale(1.15);
        this.add(playButton);

        if (GameVars.currentScene.scene.key === "SplashScene") {
            this.scene.tweens.add({
                targets: playButton,
                x: playButton.x + 10,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 3800,
                repeat: -1,
                yoyo: false
            });
        }
        
        let py = this.y;
        let alpha =  this.alpha;

        this.y += 30 * GameVars.scaleY;
        this.alpha = 0;

        this.scene.tweens.add({
            targets: this,
            y: py,
            alpha: alpha,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 450,
            delay: 2200
        });

        if (GameVars.gameData.bestScoreEndlessMode > 0) {
            const recordLabel = new Phaser.GameObjects.Text(this.scene, -5, 80, "BEST: " + GameVars.gameData.bestScoreEndlessMode.toString(), {fontFamily: "OswaldDemibold", fontSize: "20px", color: "#402a1d"});
            recordLabel.setOrigin(.5);
            recordLabel.setShadow(1, 1, "#FFFFFF", 2, true, true);
            this.add(recordLabel);
    
            const starsIcon = new Phaser.GameObjects.Image(this.scene, recordLabel.width / 2 + 15, recordLabel.y - 2, "texture_atlas_1", "star_points_gameover");
            starsIcon.setScrollFactor(0);
            starsIcon.setScale(.55);
            this.add(starsIcon);
        }
    }

    private onClickPlay(): void {

        if (this.leavingScene) {
            return;
        }

        this.leavingScene = true;

        this.scene.cameras.main.once("camerafadeoutcomplete", function (): void {

            GameManager.enterEndlessMode();
        });

        this.scene.cameras.main.fadeOut(800, 43, 43, 60);

        AudioManager.playSound("btn_click");
    }
}
