import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { StageContainer } from "../field/StageContainer";
import { GameManager } from "../../GameManager";
import { AudioManager } from "../../AudioManager";
import { Wave } from "../field/Wave";

export class Peg extends Phaser.Physics.Matter.Sprite implements WaveItem {

    public static RADIUS = 22;

    public deactivated: boolean;
    public wave: Wave;

    private hits: number;
    private f: number;
    private justHit: boolean;
    private destructable: boolean;
    private destroyBodyNextFrame: boolean;

    constructor(scene: Phaser.Scene, wave: Wave, x: number, y: number, hits: number) {

        super(scene.matter.world, x, y, "texture_atlas_1", "", { shape: "circle", friction: 0, restitution: 1, isStatic: true });

        this.wave = wave;
        this.deactivated = false;
        this.justHit = false;
        this.destroyBodyNextFrame = false;
        this.hits = hits;
        this.name = GameConstants.PEG;

        this.setCircle(14, null);
        this.setStatic(true);
        this.setBounce(1);
        this.setFriction(0, 0, 0);

        this.setCollisionCategory(GameConstants.PEGS_COLLISION_CATEGORY);
        this.setCollidesWith(GameConstants.BALLS_COLLISION_CATEGORY);

        if (this.hits === -1) {
            this.destructable = false;
        } else {
            this.destructable = true;
        }

        this.setPegFrame();
        
        // HAY Q HACER ESTO PQ EL METODO UPDATE NO SE UTILIZA DE MANERA AUTOMATICA
        this.scene.sys.updateList.add(this);
    }

    public preUpdate(time: number, delta: number): void {

        super.preUpdate(time, delta);

        if (!GameVars.placingExtraForwards) {
            
            const cameraLimits = GameManager.getCameraLimits();

            if (this.hits !== 0 && this.y > cameraLimits.bottomY) {

                StageContainer.currentInstance.removeItem(this);
                return;
            }
        }

        if (this.destroyBodyNextFrame) {
            this.destroyBodyNextFrame = false;
            const body: any = this.body;
            body.destroy();
        }

        if (this.justHit) {

            this.f ++;

            if (this.f === 3) {
                this.justHit = false;
                this.setScale(1);
                this.setOrigin(.5);
            }
        }
    }

    public deactivate(): void {

        if (!this.scene) {
            return;
        }
        
        this.deactivated = true;
        
        this.alpha = GameConstants.ALPHA_DEACTIVATED_ITEMS;

        this.setCollidesWith([]);
    }

    public hit(): void {

        if (this.destructable) {

            this.hits --;

            if (this.hits === 0) {

                this.destroyBodyNextFrame = true;
    
                this.play("peg-disappear");

                this.on("animationcomplete", function(): void {
                    StageContainer.currentInstance.removeItem(this);
                }, this);

                AudioManager.playSound("mushroom_pop");

            } else {

                this.justHit = true;
                this.f = 0;
                this.setScale(1.25);
                this.setOrigin(.5 + .05 - .1 * Math.random(), .5 + .05 - .1 * Math.random());

                this.setPegFrame();

                AudioManager.playSound("mushroom_impact");
            }

        } else {

            this.justHit = true;
            this.f = 0;
            this.setScale(1.25);
            this.setOrigin(.5 + .05 - .1 * Math.random(), .5 + .05 - .1 * Math.random());

            AudioManager.playSound("rebote_pin");
        }
    }

    private setPegFrame(): void {

        switch (this.hits) {

            case 4:
                this.setFrame("peg-4");
                break;
            case 3:
                this.setFrame("peg-3");
                break;
            case 2:
                this.setFrame("peg-2");
                break;
            case 1:
                this.setFrame("peg-1");
                break;
            case -1:
                this.setFrame("solid-peg");
                break;
            default:
        }
    }
}
