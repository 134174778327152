import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { StageContainer } from "../field/StageContainer";
import { GameManager } from "../../GameManager";
import { AudioManager } from "../../AudioManager";
import { Wave } from "../field/Wave";

export class ElectricPeg extends Phaser.GameObjects.Container implements WaveItem {

    private static id = 0;

    public deactivated: boolean;
    public moves: boolean;
    public isMoving: boolean;
    public isSwitch: boolean;
    public isBesidesGoal: boolean;
    public caged: boolean;
    public wave: Wave;
    public pegBody: Phaser.Physics.Matter.Sprite;
    
    private _x: number;
    private _y: number;
    private f: number;
    private switching: boolean;
    private cageLeftImg: Phaser.GameObjects.Image;
    private cageRightImg: Phaser.GameObjects.Image;
    private openCageNextFrame: boolean;
    private id: number;

    constructor(scene: Phaser.Scene, wave: Wave, x: number, y: number, moves?: boolean, isSwitch?: boolean, caged?: boolean) {

        super(scene);

        ElectricPeg.id ++;

        this.id = ElectricPeg.id;
        this.wave = wave;
        this.deactivated = false;
        this.isSwitch = isSwitch;
        this.switching = false;
        this.caged = caged || false;
        this.name = GameConstants.ELECTRIC_PEG;
        this.openCageNextFrame = false;
        this.isBesidesGoal = false;

        this._x = x;
        this._y = y;
        this.f = 0;

        this.moves = moves || false;
        this.isMoving = false;

        this.pegBody = this.scene.matter.add.sprite(x, y, "texture_atlas_1", "", {shape: "circle", isSensor: false, isStatic: true});
        this.pegBody.name = GameConstants.ELECTRIC_PEG;
        this.add(this.pegBody);

        if (this.caged) {

            this.pegBody.setCircle(27.5, null);

            this.addCage();

        } else {

            this.pegBody.setCircle(16, null);
        
            this.cageLeftImg = null;
            this.cageRightImg = null;
        }
        
        this.pegBody.setStatic(!this.moves);
        this.pegBody.setIgnoreGravity(true);
        this.pegBody.setCollisionCategory(GameConstants.PEGS_COLLISION_CATEGORY);
        this.pegBody.setCollidesWith(GameConstants.BALLS_COLLISION_CATEGORY);
        this.pegBody.setFriction(0, 0, 0);
        this.pegBody.setFixedRotation();

        if (this.isSwitch) {
            this.pegBody.play("switch");
        } else {
            this.pegBody.play("peg-irradiate");
        }

        const numFrames = this.pegBody.anims.currentAnim.frames.length;
        const startFrame = this.pegBody.anims.currentAnim.frames[Math.floor(numFrames * Math.random())];
        this.pegBody.anims.setCurrentFrame(startFrame);

        // HAY Q HACER ESTO PQ EL METODO UPDATE NO SE UTILIZA DE MANERA AUTOMATICA
        this.scene.sys.updateList.add(this);
    }

    public preUpdate(time: number, delta: number): void {

        if (!GameVars.scrolling || GameVars.placingExtraForwards) {
            return;
        }

        const cameraLimits = GameManager.getCameraLimits();

        if (this.pegBody.y > cameraLimits.bottomY) {
            StageContainer.currentInstance.removeItem(this);
            return;
        }

        if (this.moves && !this.isMoving) {

            const cameraYTop = this.scene.cameras.main.scrollY - StageContainer.DELTA_Y_WAVE - 50; // los 15 son para que no se empiece a mover de golpe, se puede precalcular
            
            if (this.pegBody.y * StageContainer.currentInstance.scaleY > cameraYTop) {

                this.release();
            }
        }

        if (this.isMoving) {

            this._y += 1.5;

            // para simular que es un body cinematico
            this.pegBody.setPosition(this._x, this._y);
            this.pegBody.setVelocity(0, 1.5);
            this.angle = 0;
        }

        if (this.switching) {

            this.f ++;

            if (this.f === 4) {
                this.switching = false;
                this.isSwitch = !this.isSwitch;
            }
        }

        if (this.openCageNextFrame) {

            this.f ++;

            if (this.f > 2) {
                this.openCageNextFrame = false;
                this.caged = false;

                this.pegBody.setCircle(16, null);
                this.pegBody.setStatic(!this.moves);
                this.pegBody.setIgnoreGravity(true);
                this.pegBody.setCollisionCategory(GameConstants.PEGS_COLLISION_CATEGORY);
                this.pegBody.setCollidesWith(GameConstants.BALLS_COLLISION_CATEGORY);
                this.pegBody.setFriction(0, 0, 0);
            }
        }
    }

    public deactivate(): void {

        if (!this.scene) {
            return;
        }
        
        this.deactivated = true;

        this.pegBody.alpha = GameConstants.ALPHA_DEACTIVATED_ITEMS;

        this.pegBody.setCollidesWith([]);
    }

    public switch(): void {

        this.switching = true;
        this.f = 0;

        // cambiar la animacion
        if (this.isSwitch) {
            this.pegBody.play("peg-irradiate");
        } else {
            this.pegBody.play("switch");
        }

        const numFrames = this.pegBody.anims.currentAnim.frames.length;
        const startFrame = this.pegBody.anims.currentAnim.frames[Math.floor(numFrames * Math.random())];
        this.pegBody.anims.setCurrentFrame(startFrame);

        AudioManager.playSound("switch");
    }

    public release(): void {

        this.isMoving = true;

        StageContainer.currentInstance.onElectricPegReleased(this.y);
    }

    public openCage(): void {

        this.openCageNextFrame = true;
        this.f = 0;

        this.scene.tweens.add({
            targets: this.cageLeftImg,
            x: this.cageLeftImg.x - 10,
            y: this.cageLeftImg.y + 35,
            angle: -20,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });

        this.scene.tweens.add({
            targets: this.cageLeftImg,
            alpha: 0,
            ease: Phaser.Math.Easing.Cubic.Out,
            delay: 200,
            duration: 300,
            onComplete: function(): void {
                this.cageLeftImg.destroy();
            },
            onCompleteScope: this
        });

        this.scene.tweens.add({
            targets: this.cageRightImg,
            x: this.cageRightImg.x + 10,
            y: this.cageRightImg.y + 35,
            angle: 20,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });

        this.scene.tweens.add({
            targets: this.cageRightImg,
            alpha: 0,
            ease: Phaser.Math.Easing.Cubic.Out,
            delay: 200,
            duration: 300,
            onComplete: function(): void {
                this.cageRightImg.destroy();
            },
            onCompleteScope: this
        });

        AudioManager.playSound("cage_open");
    }

    private addCage(): void {

        this.cageLeftImg = new Phaser.GameObjects.Image(this.scene, this._x, this._y + 25, "texture_atlas_1", "cageLeftPeg");  
        this.cageLeftImg.setOrigin(1);
        this.add(this.cageLeftImg);
        
        this.cageRightImg = new Phaser.GameObjects.Image(this.scene, this._x, this._y + 25, "texture_atlas_1", "cageLeftPeg"); 
        this.cageRightImg.setOrigin(1); 
        this.cageRightImg.scaleX = -1;
        this.add(this.cageRightImg);
    }
}
