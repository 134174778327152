import { Wave } from "../field/Wave";
import { GameConstants } from "../../GameConstants";
import { StageContainer } from "../field/StageContainer";
import { GameVars } from "../../GameVars";
import { GameManager } from "../../GameManager";
import { AudioManager } from "../../AudioManager";

export class WallGate extends Phaser.GameObjects.Container {

    private static id = 0;

    public side: string;
    public gateLength: number;
    public wave: Wave;
    public id: number;

    private flare: Phaser.GameObjects.Image;
    private portalImg: Phaser.GameObjects.Image;
    private portalTintedImg: Phaser.GameObjects.Image;
    private portalContainer: Phaser.GameObjects.Container;
    private timeBallEnteredGate: number;

    constructor(scene: Phaser.Scene, side: string, py: number, gateLength: number, wave: Wave) {
        
        super(scene);

        WallGate.id ++;

        this.id = WallGate.id;

        this.side = side;
        this.gateLength = gateLength * Wave.DY;
        this.wave = wave;
        this.name = GameConstants.WALL_GATE;

        this.timeBallEnteredGate = 0;
        
        const width = 16;

        if (this.side === GameConstants.RIGHT) {
            this.x = GameConstants.FIELD_WIDTH;
        }

        this.y = py;

        if (GameConstants.SHOW_PHYSICS_BODIES) {

            const debugGraphics = new Phaser.GameObjects.Graphics(this.scene);
            debugGraphics.fillStyle(0xFF0000, .5);
            debugGraphics.fillRect(0, 0, width, gateLength * Wave.DY);  

            debugGraphics.fillStyle(0x0000FF, .5);
            debugGraphics.fillCircle(0, 0, 3);

            this.add(debugGraphics);

            if (this.side === GameConstants.RIGHT) {
                debugGraphics.x = -16;
            }
        }

        this.portalContainer = new Phaser.GameObjects.Container(this.scene);
        this.portalContainer.y = this.gateLength / 2;
        this.add( this.portalContainer);

        this.portalImg = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "portal");
        this.portalContainer.add(this.portalImg);

        this.portalTintedImg = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "portal_tinted");
        this.portalTintedImg.visible = false;
        this.portalContainer.add(this.portalTintedImg);

        this.portalContainer.scaleX = .35;
        this.portalContainer.scaleY = this.gateLength / this.portalImg.height * 1.1;

        this.scene.tweens.add({
            targets: this.portalImg,
            angle: 360,
            ease: Phaser.Math.Easing.Linear.Linear,
            duration: 4000,
            yoyo: false,
            repeat: -1
        });

        this.flare = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "portal_flare");
        this.flare.blendMode = Phaser.BlendModes.OVERLAY;

        if (this.side === GameConstants.LEFT) {
            this.flare.x = 30;
            this.flare.scaleX = -1;
        } else {
            this.flare.x = -30;
        }
        this.flare.visible = false;
        this.portalContainer.add(this.flare);

        // HAY Q HACER ESTO PQ EL METODO UPDATE NO SE UTILIZA DE MANERA AUTOMATICA
        this.scene.sys.updateList.add(this);
    }

    public preUpdate(time: number, delta: number): void {

        if (!GameVars.placingExtraForwards) {

            const cameraLimits = GameManager.getCameraLimits();

            if (this.y > cameraLimits.bottomY) {
                StageContainer.currentInstance.removeItem(this);
                return;
            }
        }

        if (this.portalTintedImg.visible) {
            this.portalTintedImg.rotation = this.portalImg.rotation;
        }
    }

    public isGateInViewPort(): boolean {

        if (this.scene.game.getTime() - this.timeBallEnteredGate < 100) {
            return false;
        }

        const cameraLimits = GameManager.getCameraLimits();

        if (this.y + 50 > cameraLimits.topY && this.y + this.gateLength - 50 < cameraLimits.bottomY) {
            return true;
        } else {

            if (this.gateLength > GameConstants.GAME_HEIGHT) {

                if (this.y < cameraLimits.topY && this.y + this.gateLength > cameraLimits.bottomY) {
                    return true;
                } else if (this.y > cameraLimits.topY || this.y + this.gateLength  < cameraLimits.bottomY) {
                    return true;
                }

            } else {
                return false;
            } 
        }
    }

    public ballExitedGate(ballBounced: boolean): void {

        if (!ballBounced) {

            this.flare.visible = true;
            this.flare.alpha = 0;

            this.scene.tweens.add({
                targets: this.flare,
                alpha: 1,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 100,
                yoyo: true,
                onComplete: function(): void {
                    this.flare.visible = false;
                },
                onCompleteScope: this
            });

            this.portalContainer.setScale(.35 * 1.25, this.gateLength / this.portalImg.height * 1.1 * 1.25);

            this.scene.tweens.add({
                targets: this.portalContainer,
                scaleX: .35,
                scaleY: this.gateLength / this.portalImg.height * 1.1,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 2500
            });
        }
    }

    public ballEnteredGate(ballBounced: boolean): void {

        if (this.scene.game.getTime() - this.timeBallEnteredGate < 100) {
            ballBounced = false;
        }

        this.timeBallEnteredGate = this.scene.game.getTime();

        if (ballBounced) {

            this.portalTintedImg.visible = true;
            this.portalTintedImg.alpha = 1;

            // las escalas normal
            this.portalContainer.scaleX = .35;
            this.portalContainer.scaleY = this.gateLength / this.portalImg.height * 1.1;

            this.portalContainer.setScale(.35 * .65, this.gateLength / this.portalImg.height * 1.1 * .65);

            this.scene.tweens.add({
                targets: this.portalContainer,
                scaleX: .35,
                scaleY: this.gateLength / this.portalImg.height * 1.1,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 2500
            });

            this.scene.tweens.add({
                targets: this.portalTintedImg,
                alpha: 0,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 1250,
                onComplete: function(): void {
                    this.portalTintedImg.visible = false;
                },
                onCompleteScope: this
            });

            AudioManager.playSound("portal_blocked");

        } else {

            this.portalContainer.setScale(.35 * .75, this.gateLength / this.portalImg.height * 1.1 * .75);

            this.scene.tweens.add({
                targets: this.portalContainer,
                scaleX: .35,
                scaleY: this.gateLength / this.portalImg.height * 1.1,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 2500
            });

            AudioManager.playSound("portal");
        }
    }
}
