import { GameVars } from "../../../GameVars";
import { GameConstants } from "../../../GameConstants";
import { Button } from "../../../utils/Utils";
import { GameManager } from "../../../GameManager";
import { AudioManager } from "../../../AudioManager";
import { ScoreContainer } from "./ScoreContainter";
import { FieldManager } from "../FieldManager";

export class GameOverEndlessMode extends Phaser.GameObjects.Container {

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        this.setScrollFactor(0);

        const scoreContainer = new ScoreContainer(this.scene);
        scoreContainer.x = GameConstants.GAME_WIDTH / 2;
        scoreContainer.y = -260 / GameVars.scaleY;
        this.addAt(scoreContainer, 0);
        
        const replayButton = new Button(this.scene, GameConstants.GAME_WIDTH / 2, 50, "texture_atlas_1", "btn_capsule_replay_off", "btn_capsule_replay_on", true);
        replayButton.onDown(this.onReplayEndlessMode, this);
        replayButton.setScrollFactor(0);
        this.add(replayButton);

        this.scene.tweens.add({
            targets: replayButton,
            scaleX: 1.065,
            scaleY: 1.065,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 800,
            repeat: -1,
            yoyo: false
        });

        if (!GameVars.continued) {
            
            const continueButton = new Button(this.scene, GameConstants.GAME_WIDTH / 2, -40, "texture_atlas_1", "button_continue_off", "button_continue_on", true);
            continueButton.onDown(this.onContinue, this);
            continueButton.setScrollFactor(0);
            this.add(continueButton); 

            this.scene.tweens.add({
                targets: continueButton,
                scaleX: 1.065,
                scaleY: 1.065,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 800,
                delay: 400,
                repeat: -1,
                yoyo: false
            });
        }
    }

    private onReplayEndlessMode(): void {

        GameManager.enterEndlessMode();

        AudioManager.playSound("btn_click");
    }

    private onContinue(): void {

        FieldManager.onContinue();
    }
}
