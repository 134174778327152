import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { StageContainer } from "../field/StageContainer";
import { GameManager } from "../../GameManager";
import { AudioManager } from "../../AudioManager";
import { Wave } from "../field/Wave";

 export class StarsEmitter extends Phaser.Physics.Matter.Sprite implements WaveItem {

    public deactivated: boolean;
    public isUnicorn: boolean;
    public wave: Wave;

    private f: number;
    private justHit: boolean;
   
    constructor(scene: Phaser.Scene, wave: Wave, x: number, y: number) {

        super(scene.matter.world, x, y, "texture_atlas_1");

        this.wave = wave;

        const d = Math.floor(-this.y  / GameConstants.PIXELS_TO_METERS_FACTOR * 10) / 10;

        if (d > 75) {
            this.isUnicorn = true;
            this.setFrame("coin_emissor_plus_01");
            this.play("piggy-pulse-plus");
        } else {
            this.isUnicorn = false;
            this.setFrame("coin_emissor_01");
        }

        this.justHit = false;
        this.deactivated = false;
        this.name = GameConstants.STARS_EMITTER;

        if (this.x < GameConstants.GAME_WIDTH / 2) {
            this.scaleX = -1;
        }

        this.setRectangle(92, 60, {chamfer: {radius: 30}});
        this.setStatic(true);
        this.setBounce(1);
        this.setFriction(0, 0, 0);
        this.setCollisionCategory(GameConstants.PEGS_COLLISION_CATEGORY);
        this.setCollidesWith(GameConstants.BALLS_COLLISION_CATEGORY);

        // HAY Q HACER ESTO PQ EL METODO UPDATE NO SE UTILIZA DE MANERA AUTOMATICA
        this.scene.sys.updateList.add(this);
    }

    public preUpdate(time: number, delta: number): void {

        super.preUpdate(time, delta);
    
        const cameraLimits = GameManager.getCameraLimits();

        if (this.y > cameraLimits.bottomY) {

            StageContainer.currentInstance.removeItem(this);
            return;
        }

        if (this.justHit) {

            this.f ++;

            if (this.f === 5) {
                this.setScale(this.scaleX < 0 ? -1 : 1, 1);
            }

            if (this.f === 15) {
                this.justHit = false;

                if (this.isUnicorn) {
                    this.play("piggy-pulse-plus");
                } else {
                    this.setFrame("coin_emissor_01");
                }
            }

        } else if (this.isUnicorn) {

            if (Math.random() > .9965  && (this.y > cameraLimits.topY - 60) && !GameVars.gameOver) {
                
                AudioManager.playSound("pig_idle");
            }

        } else {

            if (Math.random() > .9965  && (this.y > cameraLimits.topY - 60)) {

                this.play("piggy-pulse");
                if (!GameVars.gameOver) {
                    AudioManager.playSound("pig_idle");
                }
            }

            if (Math.random() > .992 && this.frame.name.indexOf("01") !== -1) {
                this.setFrame("coin_emissor_blinking");
                this.f = 0;
            }

            if (this.frame.name.indexOf("blinking") !== -1) {
                if (this.f ++ === 10) {
                    this.setFrame("coin_emissor_01");
                }
            }
        }
    }

    public deactivate(): void {

        if (!this.scene) {
            return;
        }
        
        this.deactivated = true;

        this.alpha = GameConstants.ALPHA_DEACTIVATED_ITEMS;

        this.setCollidesWith([]);
    }

    public hit(): void {
        
        this.justHit = true; 
        this.f = 0;

        if (this.isUnicorn) {
            this.anims.stop();
        }

        this.setFrame(this.isUnicorn ? "coin_emissor_plus_hit" : "coin_emissor_hit");
        this.setScale(this.scaleX < 0 ? -1.25 : 1.25, 1.25);

        AudioManager.playSound("pig_hit", false, .5);
    }
}
