import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { AudioManager } from "../../AudioManager";
import { FieldManager } from "./FieldManager";

export class TrophyIntroLayer extends Phaser.GameObjects.Container {

    private coinsContainer: Phaser.GameObjects.Container;
    private f: number;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        this.setScrollFactor(0);

        this.f = 0;

        const darkBackground = new Phaser.GameObjects.Graphics(this.scene);
        darkBackground.fillStyle(0x223845);
        darkBackground.fillRect(0, 0, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT);
        darkBackground.alpha = .15;
        darkBackground.setInteractive();
        darkBackground.on("pointerover", () => {
            // nada
        }, this);
        this.add(darkBackground);

        const scaledItemsContainer = new Phaser.GameObjects.Container(this.scene);
        scaledItemsContainer.x = GameConstants.GAME_WIDTH / 2;
        scaledItemsContainer.y = GameConstants.GAME_HEIGHT / 2 - 100 * GameVars.scaleY;
        scaledItemsContainer.scaleY = GameVars.scaleY;
        this.add(scaledItemsContainer);

        const stripe = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_2", "trophy_ribbon");
        stripe.scaleX = GameConstants.GAME_WIDTH / stripe.width;
        scaledItemsContainer.add(stripe);

        const trophyNameLabel = new Phaser.GameObjects.Text(this.scene, GameConstants.GAME_WIDTH / 2 - 5, 132, "... ENDLESS BOUNCE", {fontFamily: "OswaldDemibold", fontSize: "24px", color: "#e1ebe9"});
        trophyNameLabel.setOrigin(1);
        scaledItemsContainer.add(trophyNameLabel);

        const tournamentStar = new Phaser.GameObjects.Image(this.scene, 0, 55, "texture_atlas_1", "trophy_star");
        tournamentStar.setScale(.85);
        scaledItemsContainer.add(tournamentStar);
       
        this.coinsContainer = new Phaser.GameObjects.Container(this.scene);
        this.coinsContainer.setScale(.85);
        scaledItemsContainer.add(this.coinsContainer);
       
        this.scene.tweens.add({
            targets: tournamentStar,
            scaleX: .9,
            scaleY: .9,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 450,
            repeat: -1,
            yoyo: true
        });
       
        const bouncingBall = new Phaser.GameObjects.Image(this.scene, 0, tournamentStar.y, "texture_atlas_1", "endless_bounce_icon");
        scaledItemsContainer.add(bouncingBall);

        bouncingBall.setScale(.9);

        this.scene.tweens.add({
            targets: bouncingBall,
            scaleX: .85,
            scaleY: .85,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 450,
            repeat: -1,
            yoyo: true
        });

        if (GameVars.gameData.bestScoreEndlessMode > 0) {

            const recordLabel = new Phaser.GameObjects.Text(this.scene, 0, 165, "YOUR RECORD: " + GameVars.gameData.bestScoreEndlessMode, {fontFamily: "OswaldDemibold", fontSize: "32px", color: "#223845"});
            recordLabel.setOrigin(.5);
            scaledItemsContainer.add(recordLabel);

            const starsIcon = new Phaser.GameObjects.Image(this.scene, recordLabel.width / 2 + 28, recordLabel.y - 2, "texture_atlas_1", "star_points_gameover");
            starsIcon.setScrollFactor(0);
            starsIcon.setScale(.7);
            scaledItemsContainer.add(starsIcon);
        }

        // el logo del juego
        const flappy = new Phaser.GameObjects.Image(this.scene, 0, -24, "texture_atlas_2", "title_flappy");
        flappy.setScale(.65);
        scaledItemsContainer.add(flappy);

        const foot = new Phaser.GameObjects.Image(this.scene, -50, 90, "texture_atlas_2", "title_foot");
        foot.setScale(.65);
        scaledItemsContainer.add(foot);

        const chinko = new Phaser.GameObjects.Image(this.scene, 60, 50, "texture_atlas_2", "title_chinko");
        chinko.setScale(.65);
        scaledItemsContainer.add(chinko);

        this.scene.tweens.add({
            targets: this,
            alpha: 0,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 350,
            delay: 3000,
            onComplete: function(): void {
                FieldManager.onTournamentIntroSeen();
            },
            onCompleteScope: this
        });

     
        // HAY Q HACER ESTO PQ EL METODO UPDATE NO SE UTILIZA DE MANERA AUTOMATICA
        this.scene.sys.updateList.add(this);
    }

    public preUpdate(time: number, delta: number): void {
        
        this.f ++;

        if (this.f === 8) {

            this.f = 0;
            // emitir mondedas
            const coin = new Phaser.GameObjects.Sprite(this.scene, 0, 55, "texture_atlas_1", "star_points_1");
            this.coinsContainer.add(coin);
        
            const angle = 2 * Math.PI * Math.random();
            const d = 120 + 70 * Math.random();

            this.scene.tweens.add({
                targets: coin,
                x: coin.x + d * Math.cos(angle),
                y: coin.y + d * Math.sin(angle),
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 350
            });

            this.scene.tweens.add({
                targets: coin,
                alpha: 0,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 250,
                delay: 350,
                onComplete: function(): void {
                    coin.destroy();
                },
                onCompleteScope: this
            });
        }
    }
}
