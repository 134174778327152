import { GameConstants } from "../../../GameConstants";
import { GameVars } from "../../../GameVars";
import { GameOverEndlessMode } from "./GameOverEndlessMode";
 
export class GameOverLayer extends Phaser.GameObjects.Container {

    public static currentInstance: GameOverLayer;

    private gameOverSpine: any;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        GameOverLayer.currentInstance = this;

        const stripeContainer = new Phaser.GameObjects.Container(this.scene);
        stripeContainer.scaleY = GameVars.scaleY;
        stripeContainer.setScrollFactor(0);
        this.add(stripeContainer);

        this.gameOverSpine = (this.scene as any).add.spine(0, 25 * GameVars.scaleY, "characters");
        this.gameOverSpine.skeleton.setToSetupPose();
        this.gameOverSpine.scaleY = GameVars.scaleY;
        this.gameOverSpine.setScrollFactor(0);
        this.gameOverSpine.play("gameover", true);
        this.add(this.gameOverSpine);

        this.gameOverSpine.x = GameConstants.GAME_WIDTH / 2;

        if (this.scene.game.scale.displaySize.aspectRatio < .75) {

           if (this.scene.game.scale.displaySize.aspectRatio < .5) {
                this.gameOverSpine.y = 195;
                this.gameOverSpine.scaleX *= 1.35;
                this.gameOverSpine.scaleY *= 1.35;
            } else {
                this.gameOverSpine.y = 170;
                this.gameOverSpine.scaleX *= 1.25;
                this.gameOverSpine.scaleY *= 1.25;
            }
        } else {
           
            this.gameOverSpine.y = 145;
        }

        stripeContainer.y = GameConstants.GAME_HEIGHT - 200 * GameVars.scaleY;

        const gameOverEndlessMode = new GameOverEndlessMode(this.scene);
        stripeContainer.add(gameOverEndlessMode);

        this.alpha = 0;

        this.scene.tweens.add({
            targets: this,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 450
        });
    }

    public hideGameOverMessage(): void {
     
        this.scene.tweens.add({
            targets: this.gameOverSpine,
            y: -GameConstants.GAME_HEIGHT / 2,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 450,
            delay: 250,
            onComplete: function(): void {
                this.gameOverSpine.visible = false;
            },
            onCompleteScope: this
        }); 
    }
}
